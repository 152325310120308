<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <el-collapse-item name="1">
          <template slot="title">
           <h2>客戶申請取消預約查詢</h2>
          </template>
        <el-row :gutter="20">
          <el-col :span="7">
              <el-input placeholder="客戶名稱" v-model="qClientUserDisName">
                <template slot="prepend">客戶名稱</template>
              </el-input>
          </el-col>
          <el-col :span="7">
              <el-input placeholder="客戶帳號" v-model="qClientUserId">
                <template slot="prepend">客戶帳號</template>
              </el-input>
          </el-col>
          <el-col :span="8">
              <el-input placeholder="訂單號" v-model="qClientOrderId">
                <template slot="prepend">訂單號</template>
              </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="7">          
            門市&nbsp;&nbsp;<el-select v-model="qStoreId" :disabled="this.$store.getters.userType!='0'">
              <el-option 
                v-for="item in stores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">          
            處理狀態&nbsp;&nbsp;<el-select v-model="qStatus" style="width:120px;" :disabled="this.$store.getters.userType!='0'">
              <el-option 
                v-for="item in status"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" @click="query()">查詢</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
          </el-col>    
        </el-row>
        </el-collapse-item>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="applyDate" width="140px" label="申請時間">  </el-table-column>
        <el-table-column   prop="clientUserId" width="120px" label="帳號">  </el-table-column>
        <el-table-column   prop="clientUserDisName" width="120px" label="客戶">  </el-table-column>
        <el-table-column   prop="clientOrderId" width="160px"   label="訂單號"></el-table-column>
        <el-table-column   prop="storeDisName"  width="140px" label="店家">  </el-table-column>
        <el-table-column   prop="applyStatusDisName" width="120px" label="狀態">  </el-table-column>
        <el-table-column   prop="phone" width="140px" label="電話"></el-table-column>
        <el-table-column   prop="note" label="備註"></el-table-column>
        <el-table-column   prop="reserveNote" width="120px" label="員工訂單備註"></el-table-column>
        <!-- <el-table-column   prop="adminNote" label="備註"></el-table-column> -->
        <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>
  <!-- 明細 -->
  <el-dialog title=" " :close-on-click-modal="false" :visible.sync="dialogFormVisible" font-size="150%" width="90%" center>
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">訂單明細
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientOrderId}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶帳號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.clientUserId}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientUserDisName}}</td></tr>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">{{form.clientPhone}}</td>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.sDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單還車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.eDate}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款方式:</td>
          <td style="border-style:solid; border-width:1px;">{{form.payType}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂金:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.deposit}}</td></tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="(item, key) in clientOrderTrans" :key="key">
                {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單流程:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">狀態:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.status}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員退款銀行:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.bankName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員退款分行:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.bankBranch}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員退款帳號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.bankAccount}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">扣手續費:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.isPlusFee}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.reserveNote}}
            <!-- <el-input style="width:400px" v-model="form.note" />
            <el-button type="primary" @click="changeNote">修改備註</el-button> -->
          </td></tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">財務備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input style="width:400px" v-model="form.finNote" />
            <el-button type="primary" @click="changeFinNote">儲存備註</el-button>
          </td></tr>
          <tr v-show="form.applyStatus != 'F'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">財務處理:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-switch v-model="form.applyStatus" active-color="#13ce66" inactive-color="#ff4949" active-value="R" inactive-value="T" disabled />
            <span v-show="form.applyStatus === 'T'">已處理</span>
            <span v-show="form.applyStatus === 'R'">已退款</span>
            <!-- <el-button type="primary" @click="changeApplyStatus">儲存狀態</el-button> -->
          </td></tr>
          
        </tbody>
      </table>
    </el-form>
  <div slot="footer" class="dialog-footer">
    <!-- <el-button type="danger" @click="confirmEditStore(form)" v-if="!isHandle" style="font-size:150%">取消預約確認</el-button> -->
    <el-button @click="dialogFormVisible = false" style="font-size:150%">退  出</el-button>
  </div>
</el-dialog>
  </div>
</template>


<script>
// import * as moment from "moment/moment";
export default {
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      qClientUserId:'',qClientOrderId:'',qClientUserDisName:'',qStoreId:'',qStatus:'',
      tableData:[],
      historyFlow:[],
      clientOrderTrans: [], // 支付紀錄
      dialogFormVisible: false,
      form: {
        deposit:0,
        note: "",
        finNote: '',
        applyStatus: 'T'
      },
      formLabelWidth: '120px',
      stores: [],
      storesMap: [],
      status: [{code:'',disName:'全部'}, {code:'F',disName:'未處理'},{code:'T',disName:'已處理'},{code:'R',disName:'已退款'}],
      selectStores: [],
      flows:[],
      activeName: '1', // 搜尋手風琴預設
      assignRowBankNo: '',
      assignRowReadFlag: 'Y',
      fullscreenLoading: false,
      isHandle: false
    }
  },
  created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>=1){
          this.stores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
        });
        if(this.$route.query.qType == 'refund') {
          this.qStatus = 'F'
          this.qStoreId = this.$route.query.qStoreId
        }
        if(this.$route.query.qStoreId != undefined && this.$route.query.qStoreId != ''){
          this.qStoreId = parseInt(this.$route.query.qStoreId.split(','), 10)
        }
        this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
        }        
      });   
  },
  name: 'ClientUserApplyCancelForm',
  methods :{
    query(){
      this.fullscreenLoading = true
      let qForm= {
        page:this.pageCurrent,
        qClientUserId:this.qClientUserId,   
        qClientOrderId:this.qClientOrderId,
        qStoreId:this.qStoreId,
        qClientUserDisName:this.qClientUserDisName,
        qStatus: this.qStatus
      }
      this.$axios
        .get('../api/clientUserApplyCancelOrder/getClientOrders', {params:qForm}) 
        .then((res)=>{
          this.fullscreenLoading = false
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store=>{
            store.applyDate = this.$date_(store.applyDate)
              this.tableData.push(store)
              if (store.applyStatus === 'T') {
                store.applyStatusDisName = '已處理'
              } else if (store.applyStatus === 'R'){
                store.applyStatusDisName = '已退款'
              } else {
                store.applyStatusDisName = '未處理'
              }
            })
          this.pageTotal=res.data.pageTotal
      }).catch(()=>{
        this.fullscreenLoading = false
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
      });  
    },
    // confirmEditStore(clientOrder){
    //   this.$router.push({
    //     path:'/adminQuickGet',
    //     query: { clientOrderId:clientOrder.clientOrderId }
    //   })
        // this.$confirm('確定退回'+this.form.clientUserId+'訂金嗎?', '注意', {
        //   confirmButtonText: '確定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   this.cancelClientOrder()
        //   this.dialogFormVisible = false
        // }).catch(() => {
       
        // });      
    // },
    // cancelClientOrder(clientOrder) {
    //   this.$router.push({
    //     path:'/adminQuickGet',
    //     query: { clientOrderId:clientOrder.clientOrderId }
    //   })
      // this.fullscreenLoading = true
      // let pa = {
      //   clientOrderId: this.form.clientOrderId,
      //   deposit: this.form.deposit
      // }
      // this.$axios.post('../api/clientUserApplyCancelOrder/returnClientOrderEntirePay',pa)
      //   .then((res) => {
      //     this.fullscreenLoading = false
      //     if(res.data.rs === 'success') {
      //       this.query()
      //       this.dialogFormVisible = false
      //     }
      //     this.$alert(res.data.rsMsg, '結果',{confirmButtonText: '確定'})
      //   }).catch(() => {
      //     this.fullscreenLoading = false
      //     this.$alert('服務器錯誤', '結果',{confirmButtonText: '確定'})
      //   })
    // },
    reset(){
      this.qClientUserId=null
      this.qClientOrderId=null
      this.qSDate=null
      this.qStoreId=this.stores[0].code
      this.qClientUserDisName=null
      this.query()
    },
    showDetailForm(clientOrder){
      this.fullscreenLoading = true
      this.assignRowBankNo = clientOrder.bankNo
      this.assignRowReadFlag = clientOrder.readFlag
      this.$axios
        .get('../api/clientOrder/getClientOrderForm?clientOrderId='+clientOrder.clientOrderId)
        .then((res)=>{
          this.fullscreenLoading = false
          this.dialogFormVisible = true
          this.form = Object.assign({}, this.form, res.data[0])
          if(this.form.plusPrice=='0'||this.form.plusPrice==''){
            this.form.plusPrice = '0'
          }
          this.form.status = clientOrder.applyStatusDisName
          this.form.applyStatus = clientOrder.applyStatus
          this.form.finNote = clientOrder.note
          this.form.bankName = clientOrder.clientUserBankName
          this.form.bankAccount = clientOrder.clientUserBankAccount
          this.form.bankBranch = clientOrder.clientUserBankBranch
          this.form.isPlusFee = clientOrder.plusFee === 'T' ? '是' : '否'
          if (this.form.status === '已處理' || this.form.status === '已退款') {
            this.isHandle = true
          } else {
            this.$router.push({
              path:'/adminQuickGet',
              query: { clientOrderId:clientOrder.clientOrderId }
            })
            this.isHandle = false
            return
          }
          this.form.note = clientOrder.note
          this.form.eDate = this.$date_(this.form.eDate)
          this.form.sDate = this.$date_(this.form.sDate)
          this.historyFlow.splice(0, this.historyFlow.length)
          res.data[0].historyFlow.forEach(e=>{
            e.updateDate=this.$date_(e.updateDate)
            this.historyFlow.push(e)
          })
          this.clientOrderTrans.splice(0, this.clientOrderTrans.length)
          res.data[0].clientOrderTrans.forEach(e=>{
            if (e.moneyType == 'CASH') {
              e.moneyType = '現金'
            } else if (e.moneyType == 'CREDIT') {
              e.moneyType = '信用卡'
            } else if (e.moneyType == 'LINEPAY') {
              e.moneyType = 'LINE Pay'
            } else if (e.moneyType == 'ATM') {
              e.moneyType = 'ATM 轉帳'
            } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
            } else { e.moneyType = '未知付款方式' }
            e.updateDate=this.$date_(e.updateDate)
            this.clientOrderTrans.push(e)
            this.form.payType = e.moneyType
          })
      }).catch((res)=>{
          console.log(res)
          this.fullscreenLoading = false
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
      });
    },
    changeNote(){
      this.$confirm('是否確定更改備註?','注意', {
        confirmButtonText: '確定',
        cencelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fullscreenLoading = true
        let pa = {
          clientOrderId: this.form.clientOrderId,
          note: this.form.note}
        this.$axios.post('../api/clientUserApplyCancelOrder/changeNote',pa)
          .then(() => {
            this.$alert('備註修改成功','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
            this.query()
          }).catch(() => {
            this.$alert('備註修改失敗','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
          })
      })
    },
    changeFinNote(){
      this.$confirm('是否確定更改備註?','注意', {
        confirmButtonText: '確定',
        cencelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fullscreenLoading = true
        let pa = {
          clientOrderId: this.form.clientOrderId,
          note: this.form.finNote}
        this.$axios.post('../api/clientUserApplyCancelOrder/changeNote',pa)
          .then(() => {
            this.$alert('備註修改成功','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
            this.query()
          }).catch(() => {
            this.$alert('備註修改失敗','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
          })
      })
    },
    changeApplyStatus(){
      this.$confirm('是否確定更改處理狀態?','注意', {
        confirmButtonText: '確定',
        cencelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fullscreenLoading = true
        let pa = {
          clientOrderId: this.form.clientOrderId,
          applyStatus: this.form.applyStatus}
        this.$axios.post('../api/clientUserApplyCancelOrder/changeApplyStatus',pa)
          .then(() => {
            this.$alert('處理狀態修改成功','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
            this.query()
          }).catch(() => {
            this.$alert('處理狀態修改失敗','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
          })
      })
    }
  }
}
</script>
