<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <el-collapse-item name="1">
          <template slot="title">
            <h2>收款查詢</h2>
          </template>
          <el-row :gutter="10" style="margin-top:10px;">

            <el-col :span="24" :md="7">
              明細&nbsp;&nbsp;<el-select v-model="qType">
                <el-option 
                  v-for="item in types"
                  :key="item.code"
                  :label="item.disName"
                  :value="item.code"
                  >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="24" :md="8">
              付款方式&nbsp;&nbsp;
              <el-select v-model="qMoneyType">
                <el-option 
                  v-for="item in moneyType"
                  :key="item.code"
                  :label="item.disName"
                  :value="item.code"
                  >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="24" :md="8">
              付款結果&nbsp;&nbsp;
              <el-select v-model="qStatus">
                <el-option 
                  v-for="item in status"
                  :key="item.code"
                  :label="item.disName"
                  :value="item.code"
                  >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:10px;">
            <el-col :span="24" :md="7">
              門市&nbsp;&nbsp;<el-select v-model="qStoreId">
                <el-option 
                  v-for="item in stores"
                  :key="item.code"
                  :label="item.disName"
                  :value="item.code"
                  >
                </el-option>
              </el-select>
            </el-col>
            <el-col :gutter="10" :span="24" :md="13">
              付款日期區間 <el-date-picker
                v-model="qSDate"
                type="date"
                placeholder="付款日期">
              </el-date-picker>
              &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
                v-model="qEDate"
                type="date"
                placeholder="付款日期">
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:10px;">
            <el-col :span="24" :md="10">
                <el-input placeholder="會員名稱或帳號" v-model="qClientUserDisName">
                  <template slot="prepend">會員名稱、帳號、訂單號</template>
                </el-input>
            </el-col>
            <el-col :span="24" :md="10">
                <el-input placeholder="交易序號" v-model="qMerchantOrderNo">
                  <template slot="prepend">交易序號</template>
                </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top:10px;">
            <el-col :span="24" :md="6">
              <el-button type="primary" @click="query()">查詢</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
              <el-button type="primary" @click="downloadExcel()">下載報表</el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="clientUserId" width="120px" label="帳號">  </el-table-column>
        <el-table-column   prop="clientUserDisName" width="120px" label="客戶">  </el-table-column>
        <el-table-column   prop="clientOrderId" width="160px"   label="訂單號"></el-table-column>
        <el-table-column   prop="storeDisName"  width="120px" label="店家">  </el-table-column>
        <el-table-column   prop="disName"  width="120px" label="付款明細">  </el-table-column>
        <el-table-column   prop="payDate"  width="180px" label="付款時間">  </el-table-column>
        <el-table-column   prop="amt" align='right' width="80px" label="金額">  </el-table-column>
        <el-table-column   prop="moneyTypeDisName"  width="100px" label="付款方式">  </el-table-column>
        <el-table-column   prop="statusDisName"  label="結果">  </el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button @click="setComfirmModalEvent(true,scope.row)" type="primary" size="small">查看</el-button>
            <el-button @click="showUpdateForm(scope.row)" v-if="userInfo.auth.userType === '0'"  type="primary" size="small">編輯</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>
  <!-- 明細 -->
  <financialClientOrderDailog 
    :comfirmModal="comfirmModal"
    :comfirmModelsContent="comfirmModelsContent"
    @setComfirmModalEvent="setComfirmModalEvent"
    @comfirmModalOkEvent="comfirmModalOkEvent"
    @comfirmModalCancelEvent="comfirmModalCancelEvent">
  </financialClientOrderDailog>
  <el-dialog title="設定" :visible.sync="updateFormVisible" :close-on-click-modal="false">
    <el-row :gutter="20">
      <el-form ref="editForm">
      <el-form-item label="訂單編號" :label-width="formLabelWidth">
        {{form.clientOrderId}}
      </el-form-item>
      <el-form-item label="付款明細" :label-width="formLabelWidth">
        {{form.disName}}
      </el-form-item>
      <el-form-item label="付款日期" :label-width="formLabelWidth">
        <el-date-picker type="datetime" v-model="form.payDate" :clearable="false"></el-date-picker>
      </el-form-item>
      <el-form-item label="金額" :label-width="formLabelWidth">
        <el-input v-model.number="form.amt" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="付款方式" :label-width="formLabelWidth">
        <el-select v-model="form.moneyType">
          <el-option width=120 v-for="item in moneyType" v-show="item.code != ''" :key="item.code" :label="item.disName" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="末5碼/末4碼" :label-width="formLabelWidth" >
        <el-input v-model.number="form.moneyTypeExtend" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="狀態" :label-width="formLabelWidth" >
        <el-select v-model="form.status">
          <el-option width=120 key="SUCCESS" label="成功" value="SUCCESS"></el-option>
          <el-option width=120 key="fail" label="失敗" value="fail"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="updateFormVisible = false">取消</el-button>
      <el-button type="primary" @click="confirmUpdateTrans()">更新</el-button>
    </div>
  </el-dialog>
  </div>
</template>


<script>
import financialClientOrderDailog from '@/components/financialClientOrder/financialClientOrderDialog'
import moment from 'moment';
export default {
  components:{
    financialClientOrderDailog
  },
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      qClientUserDisName:'',qClientOrderId:'',qStoreId:'',qMoneyType:'',qDisName: '',qStatus:'SUCCESS', qSDate:null, qEDate:null,
      qType:'',
      qMerchantOrderNo:'',
      tableData:[],
      historyFlow:[],
      clientOrderTrans: [], // 支付紀錄
      dialogFormVisible: false,
      form: {
        status: '',
        moneyType: '',
        type: '',
        moneyTypeExtend: '',
        updateDate: '',
        deposit:0,
        note: ""
      },
      formLabelWidth: '120px',
      stores: [],
      storesMap: [],
      moneyType: [{code:'',disName:'全部'},{code:'CREDIT',disName:'信用卡'}, {code:'CASH',disName:'現金'},{code:'ATM',disName:'轉帳'},{code:'ICP',disName:'iCashPay'},{code:'LINEPAY',disName:'LinePay'} ],
      status: [{code:'SUCCESS',disName:'成功'},  {code:'',disName:'全部'},{code:'ERROR',disName:'失敗'}],
      disNames: [{code:'',disName:'全部'},{code:'訂金',disName:'訂金'}, {code:'尾款',disName:'尾款'}, {code:'退款',disName:'退款'}, {code:'預付',disName:'預付'}],
      types: [{code:'',disName:'全部'},
        {code:'DEPOSIT',disName:'訂金'},
        {code:'ENTIRE',disName:'尾款'},
        {code:'RETURN',disName:'退款'},
        {code:'PREPAY',disName:'預付'},
        {code:'STAGE',disName:'分期付款'},
        ],
      selectStores: [],
      flows:[],
      activeName: '1', // 搜尋手風琴預設
      assignRowBankNo: '',
      assignRowReadFlag: 'Y',
      fullscreenLoading: false,
      isHandle: false,
      comfirmModelsContent: null,
      comfirmModal: false,
      updateFormVisible: false
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>=1){
          this.stores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
        });
        this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
        }        
      });   
  },
  computed: {
      userInfo(){
        return {
          auth:{
              userType:this.$store.getters.userType
          }
        }
      },
  },
  name: 'FinancialClientOrderPayQuery',
  methods :{
    query(){
      this.fullscreenLoading = true
      let sDate = this.qSDate == null ? '' : moment(this.qSDate).format('YYYYMMDD');
      let eDate = this.qEDate == null ? '' : moment(this.qEDate).format('YYYYMMDD');
      let qForm= {page:this.pageCurrent,
       qStatus:this.qStatus,
       qEDate:eDate,
       qSDate:sDate,
       qStoreId:this.qStoreId,
       qClientUserDisName:this.qClientUserDisName,
       qMoneyType: this.qMoneyType,
       qType: this.qType,
       qMerchantOrderNo: this.qMerchantOrderNo
       }
      this.$axios
        .get('../api/BackSideFinancialClientOrderPayQuery/getClientOrderPays', {params:qForm}) 
        .then((res)=>{
          this.fullscreenLoading = false
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store=>{
              this.tableData.push(store)
              store.payDate = this.$getDateTime_('_14', store.payDate)
              // console.log(store)
              store.statusDisName = (store.status === 'SUCCESS') ? '成功' : '失敗'
              store.moneyTypeDisName = this.$payTypeDisName(store.moneyType)
            })
          this.pageTotal=res.data.pageTotal
      }).catch(()=>{
        this.fullscreenLoading = false
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' })
      });  
    },
    showUpdateForm(trans){
      this.form = Object.assign(trans)
      this.updateFormVisible = true
    },
    confirmUpdateTrans(){
      if (this.form.moneyType == 'ATM' && (this.form.moneyTypeExtend == '' || this.form.moneyTypeExtend == null)) {
        this.$alert('匯款必填末五碼', '注意', { confirmButtonText: '確定' });
        return
      }
      this.$confirm('確定要更新嗎?','注意',{ confirmButtonText: '確定', cancelButtonText: '取消', type: 'warning' }).then(()=>{
              this.fullscreenLoading = true
              let url = '../api/BackSideFinancialClientOrderPayQuery/updateOrderTrans'
              this.form.updateDate = moment(this.form.payDate).format('YYYYMMDDHHmmss')
              this.$axios.post(url,this.form).then((res) => {
                this.fullscreenLoading = false                
                if (res.data.rs === 'success') {
                  this.query()
                  this.updateFormVisible = false
                }
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
              }).catch(() => {
                this.fullscreenLoading = false
                this.$alert('服務器發生不知名錯誤', '結果', { confirmButtonText: '確定' });
              })
            }) 
    },
    reset(){
      this.qClientOrderId=''
      this.qSDate=null
      this.qEDate=null
      this.qStoreId=this.stores[0].code
      this.qClientUserDisName=''
      this.qType = ''
      this.qMerchantOrderNo = ''
      this.qMoneyType = ''
      this.qStatus = 'SUCCESS'
      // this.query()
    },
    setComfirmModalEvent (bol,clientOrder) {
      this.comfirmModal = bol
      if(clientOrder !== undefined && clientOrder !== null) {
        this.comfirmModelsContent = clientOrder
      }
    },
    comfirmModalOkEvent (bol) {
      this.comfirmModal = bol
    },
    comfirmModalCancelEvent (bol) {
      this.comfirmModal = bol
    },
    downloadExcel () {
      if(this.qSDate === null || this.qEDate === null) {
        this.$alert('請填寫付款日期區間','結果',{confirmButtonText : '確定'})
        return
      }

      if ( this.qSDate == null | this.qEDate == null ) {
        if ( this.qSDate != null | this.qEDate != null ) {
          this.$alert('請正確填寫日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( moment(this.qEDate) - moment(this.qSDate) > 90*86400000 ) { // 86400000 一天
        this.$alert('日期區間不可超過90天', '結果', { confirmButtonText: '確定' });
        return
      }
      let sDate = this.qSDate == null ? '' : moment(this.qSDate).format('YYYYMMDD');
      let eDate = this.qEDate == null ? '' : moment(this.qEDate).format('YYYYMMDD');
      
       window.open('../api/BackSideFinancialClientOrderPayQuery/downloadExcel?qStatus='+this.qStatus
       +'&qEDate='+eDate
       +'&qSDate='+sDate
       +'&qStoreId='+this.qStoreId
       +'&qClientUserDisName='+this.qClientUserDisName
       +'&qMoneyType='+ this.qMoneyType
       +'&qDisName='+this.qDisName
       +'&qMerchantOrderNo='+this.qMerchantOrderNo
       +'&qType='+this.qType)
    }
  }
}
</script>
