<template>
<el-container>
  <el-aside width="300px">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col>車輛總數：
        <el-tag style="margin-left:25px;">{{counter[0]}}</el-tag>台
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col>出租中：
          <el-tag type="danger" style="margin-left:40px;">{{counter[1]}}</el-tag>台
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col>閒置中：
          <el-tag style="margin-left:40px;">{{counter[2]}}</el-tag>台
        </el-col>
      </el-row>
    <el-row :gutter="20">      
          <el-col >所屬店家
            <el-select v-model="qStoreId" filterable>
              <el-option width=120 v-for="item in selectStores" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
            </el-select>
          </el-col>    
    </el-row>
    <el-collapse v-model="activeName">
      <el-collapse-item  name="serch">
        <template slot="title">
          <h4>進階搜尋</h4>
        </template>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col >
            <div class="grid-content bg-purple"></div>
            <el-input placeholder="車號" v-model="qCarNo">
              <template slot="prepend">車號</template>
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col >
            <div class="grid-content bg-purple"></div>
            <el-input placeholder="訂單號" v-model="qClientOrderId">
              <template slot="prepend">訂單號</template>
            </el-input>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <el-row :gutter="20" style="margin-top:10px;">
      <el-col>
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="query()">查詢</el-button>
        <el-button type="primary" @click="reset()">重置</el-button>
      </el-col>
    </el-row>
    <el-table :data="cars" stripe border style="width: 100%;" max-height="350px">
      <el-table-column prop="carNo" label="車號">
        <template slot-scope="scope" >
          <div @click="changeMapCenter(scope.row)" :id="scope.row.carNo">
          <el-tag type="danger" v-if="scope.row.clientOrderId!=null">出租中</el-tag>
          <el-tag v-else>空閒中</el-tag>{{scope.row.carNo}} 
          <br/>{{scope.row.rentingDateTime}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="voltage" label="電壓"></el-table-column>
      <el-table-column prop="soc" label="電池電量"></el-table-column>

    </el-table>
    </el-card>
  </el-aside>
  <el-main>
    <div id="map" class="google-map">test</div>
  </el-main>
</el-container>
</template>

<script>
export default {
  data() {
    return {
      ggmap:null,
      qStoreId: '',
      qCarNo: '',
      qClientOrderId: '',
      cars: [],
      selectStores: [],
      storesMap: [],
      stores: [],
      centerLat: '',
      centerLng: '',
      activeName: '1',
      ggservice : null,
      storesLocat: [],
      counter: [0,0,0],
      trackerMap: {},
      heatMapData: [],
      prev_info: null
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams',{})
      .then((res)=> {
        this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>1){
          this.selectStores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
          this.storesMap[e.code] = e.disName
          this.selectStores.push(e)
        });
        this.qStoreId = this.selectStores[0].code
      }).catch((res)=>{
        if(res.response.status==401){
          window.location = '/';
        }
    })
    this.$axios.get('../api/adminProdLocation/getStoreLocat')
      .then((res) => {
        res.data.forEach(e =>{
          this.storesLocat.push(e)
        })
      })
  },
  mounted(){
    this.query()
  },
  methods:{
    initMap() {
      let centerLat = 25.0335398
      let centerLng = 121.55620666
      this.storesLocat.forEach(store =>{
        if(store.code === this.qStoreId) {
          if (store.gpsLat != '' & store.gpsLng != '' ){
            if (parseFloat(store.gpsLat) < parseFloat(store.gpsLng)) {
              centerLng = parseFloat(store.gpsLng)
              centerLat = parseFloat(store.gpsLat)
            } else {
              centerLat = parseFloat(store.gpsLng)
              centerLng = parseFloat(store.gpsLat)
            }
          }
        }
      })
      let google = window.google
      let map = new google.maps.Map(document.getElementById("map"), {
        center: {lat: centerLat, lng: centerLng},
        zoom: 10
      });
      this.ggmap = map
      
    },
    query() {
      this.initMap()
      let google = window.google
      this.$axios.get('../api/adminProdLocation/getProdList',{
        params:{
          qStoreId: this.qStoreId,
          qCarNo: this.qCarNo,
          qClientOrderId: this.qClientOrderId
        }
        }).then((res) =>{
          if (res.data.rs === 'success') {
            this.counter[0] = res.data.counter[0]
            this.counter[1] = res.data.counter[1]
            this.counter[2] = res.data.counter[2]
            if (res.data.data.length == 0) {
              return
            }
            this.cars.splice(0, this.cars.length)
            res.data.data.forEach(e => {
              this.cars.push(e)
            })
            let params = { trackerIds: res.data.trackerIds}
            this.$axios
              .get('https://cms.mofuntravel.com.tw/iot/api/getRealTimeGps',{params})
              .then((res)=>{
                if (res.data.rs === 'success') {
                  let trackers = res.data.data
                  this.trackerMap = Object.assign({})
                  trackers.forEach(e => {
                    if (e !== null) {
                      let val = e.split(',')
                      let map = {
                        updateDate: val[1],
                        lng: (parseFloat(val[2].substring(1,4) )+ (parseFloat(val[2].substring(4)) / 60)) ,
                        lat: (parseFloat(val[3].substring(1,3) )+ (parseFloat(val[3].substring(3)) / 60)) ,
                        speed: val[4] != undefined? parseFloat(val[4]).toFixed(1) : 0
                      }
                      this.trackerMap[val[0]] = map
                    } else {
                      this.trackerMap[0] = null
                    }
                  })
                  this.cars.forEach((car) =>{
                    if (this.trackerMap[car.trackerId] !== undefined) {
                      let marker = new window.google.maps.Marker({
                        position: {lat: this.trackerMap[car.trackerId].lat, lng: this.trackerMap[car.trackerId].lng},
                        map: this.ggmap,
                        title: car.carNo
                        })
                      let carDetails = '<h3 style="width:180px">'+car.carNo+'</h3><div>店家：'+car.storeDisname+'</div>'
                      if (car.clientOrderId != null) {
                        carDetails = carDetails + '<div>租借人：'+car.clientUserDisname+'</div>'
                        carDetails = carDetails + '<div>起租時間：<br/>'+this.$date_(car.realStartDate)+'</div><div>時速：'+this.trackerMap[car.trackerId].speed+'km/h</div>'
                      }
                      carDetails = carDetails + '<div>最後更新時間：<br/>'+this.$date_(this.trackerMap[car.trackerId].updateDate)+'</div>'
                      let info = new google.maps.InfoWindow({content: carDetails })
                      marker.addListener("click", () => {
                        if (this.prev_info !== null) {
                          this.prev_info.close()
                        }
                        info.open({
                          anchor: marker,
                          map: this.ggmap,
                          shouldFocus: false,
                          })
                        this.prev_info = info
                      })
                      const prodDiv = document.getElementById(car.carNo)
                      google.maps.event.addDomListener(prodDiv, "click", () => {
                        if (this.prev_info !== null) {
                          this.prev_info.close()
                        }
                        info.open({
                          anchor: marker,
                          map: this.ggmap,
                          shouldFocus: false,
                          })
                        this.prev_info = info
                      })
                    } else if (!this.$isEmpty(car.lat) && !this.$isEmpty(car.lng)) {
                      let marker = new window.google.maps.Marker({
                        position: {lat: car.lat, lng: car.lng},
                        map: this.ggmap,
                        title: car.carNo
                        })
                      let carDetails = '<h3 style="width:180px">'+car.carNo+'</h3><div>店家：'+car.storeDisname+'</div>'
                      if (car.clientOrderId != null) {
                        carDetails = carDetails + '<div>租借人：'+car.clientUserDisname+'</div>'
                        carDetails = carDetails + '<div>起租時間：<br/>'+this.$date_(car.realStartDate)
                      }
                      carDetails = carDetails + '<div>最後更新時間：<br/>'+this.$date_(car.lastUpdateDate)+'</div>'
                      let info = new google.maps.InfoWindow({content: carDetails })
                      marker.addListener("click", () => {
                        if (this.prev_info !== null) {
                          this.prev_info.close()
                        }
                        info.open({
                          anchor: marker,
                          map: this.ggmap,
                          shouldFocus: false,
                          })
                        this.prev_info = info
                      })
                      const prodDiv = document.getElementById(car.carNo)
                      google.maps.event.addDomListener(prodDiv, "click", () => {
                        if (this.prev_info !== null) {
                          this.prev_info.close()
                        }
                        info.open({
                          anchor: marker,
                          map: this.ggmap,
                          shouldFocus: false,
                          })
                        this.prev_info = info
                      })
                    } else {
                      console.log(car.carNo+'此車車機號/Purism機號' + (car.trackerId || car.ecoAuto) + '無訊號')
                    }
                  })
                }
            })
          } else {
            this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
          }
      }).catch((res)=>{
        console.log(res)
      })//車輛清單
    },
    changeMapCenter(locat) {
      if (this.trackerMap[locat.trackerId] === undefined) {
        this.$alert('車機編號'+locat.trackerId+'無訊號，請檢查車機編號是否正確與是否有傳遞訊號', '結果', { confirmButtonText: '確定' });
        return
      }
      this.ggmap.setCenter({lat: this.trackerMap[locat.trackerId].lat,lng: this.trackerMap[locat.trackerId].lng})
    },
    reset() {
      this.qStoreId = ''
      this.qCarNo = ''
      this.qClientOrderId = ''
      // this.query()
    }
  },
  name: 'AdminProdLocation',
  components: {},
  computed: {}
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 600px;
}
a.active {
  border-bottom: 3px solid #007bff;
}
</style>