<template>
  <div id="app">
    <el-container style=" border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px" v-show="f2.auth.login"> 
          <div style="float: left;height:100%;" v-show="phone">
            <el-button @click="menuOpen = !menuOpen">功能列</el-button>
          </div>
          <span style="float: left;font-size: 20px;" v-show="titleAppend === ''">Mofun摩拓旅程門市管理平台</span>
          <span style="float: left;font-size: 20px;color:red;" v-show="titleAppend !== ''">摩拓旅程{{titleAppend}}</span>
          <el-popover  v-if="false" 
            placement="bottom"
            width="250"
            trigger="click">
            帳號：<el-input  placeholder="請輸入內容"></el-input>
            密碼：<el-input  placeholder="請輸入內容"></el-input>
            <el-button @click="login" type="primary"> 登入</el-button>
            <el-button slot="reference" >帳號登入</el-button>
          </el-popover>

          <!-- <el-dropdown trigger="click">
            <span class="el-dropdown-link" style="cursor: pointer; color:#FFFFFF">
              {{$t('lang')}}<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu slot="dropdown" >
              <el-dropdown-item ><el-button size="medium" @click.native="changelangtw" :type="colortw">繁體中文</el-button></el-dropdown-item>
              <el-dropdown-item ><el-button size="medium" @click.native="changelangen" :type="coloren">ENGLISH</el-button></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->

          <el-dropdown trigger="click" v-if="f2.auth.login">                    
            <span class="el-dropdown-link" style="cursor: pointer;" >
              {{$t('appAccountsettings')}}{{f2.auth.disName}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-circle-check" @click.native="logout()">登出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </el-header>
      
      <el-container>
        <el-aside width="200px" v-if="f2.auth.login" v-show="menuOpen">      
          <el-menu  :unique-opened="true" :default-active="onmenuPath" >                        
            <!-- <el-menu-item class="el-icon-message" @click="changeAuthFromVuex()" >首頁</el-menu-item> -->
            <el-submenu index="9" >            
              <template slot="title" ><i class="el-icon-message"></i>{{$t('appKanban')}}</template>
              <!-- <el-menu-item index="9-1" @click="trans('/adminQuickGet')">看版</el-menu-item> -->
              <el-menu-item index="adminProdStatus" @click="trans('/adminProdStatus')">{{$t('appVehiclestatus')}}</el-menu-item>
              <el-menu-item index="bookingStatus" @click="trans('/bookingStatus')">{{$t('appSchedule')}}</el-menu-item>
              <el-menu-item index="adminProdLocation" @click="trans('/adminProdLocation')">{{$t('appProdLocation')}}</el-menu-item>
              <el-menu-item index="adminProdHeatmap" @click="trans('/adminProdHeatmap')">{{$t('appProdHeatmap')}}</el-menu-item>
              <el-menu-item index="adminProdPath" @click="trans('/adminProdPath')">{{$t('appProdPath')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="2" >
              <template slot="title" ><i class="el-icon-message"></i>{{$t('appOrder')}}</template>
              <el-menu-item index="onePageGet" @click="trans('/onePageGet')">一頁式租車</el-menu-item>
              <el-menu-item index="onePageReturn" @click="trans('/onePageReturn')">一頁式還車</el-menu-item>
              <el-menu-item index="booking" @click="trans('/booking')">{{$t('appScheduledjob')}}</el-menu-item>
              <el-menu-item index="adminQuickGet" @click="trans('/adminQuickGet')">{{$t('appQuickpickupthecar')}}</el-menu-item>
              <el-menu-item index="adminQuickReturn" @click="trans('/adminQuickReturn')">{{$t('appReturnthecarquickly')}}</el-menu-item>
              <el-menu-item index="adminClientOrder" @click="trans('/adminClientOrder')">{{$t('appOrdermanagement')}}</el-menu-item><!--<el-badge :value="12" class="item"></el-badge>-->
          <!--<el-menu-item index="adminAdditionalClientOrder" @click="trans('/adminAdditionalClientOrder')">{{$t('appReplenishmentjob')}}</el-menu-item> --><!--<el-badge :value="12" class="item"></el-badge>-->
              <el-menu-item index="adminAlterClientOrder" @click="trans('/adminAlterClientOrder')" v-if="f2.auth.userType!='2'">修改訂單</el-menu-item><!--<el-badge :value="12" class="item"></el-badge>-->
              <el-menu-item index="adminStageTrans" @click="trans('/adminStageTrans')" v-if="f2.auth.userType=='0'">修改長租分期</el-menu-item>
            </el-submenu>
            <el-submenu index="3" v-if="f2.auth.userType!='3'">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appStoresetting')}}</template>
              <el-menu-item index="adminStore" @click="trans('adminStore')" v-if="f2.auth.userType!='2'">{{$t('appStoresetting')}}</el-menu-item>
              <el-menu-item index="adminContinueHoliday" @click="trans('adminContinueHoliday')" v-if="f2.auth.userType!='2'">連續假期設定</el-menu-item>
              <el-menu-item index="adminArea" @click="trans('adminArea')" v-if="f2.auth.userType=='0'">{{$t('appAreasetting')}}</el-menu-item>
              <el-menu-item index="adminStoreGroup" @click="trans('adminStoreGroup')" v-if="f2.auth.userType=='0'">門市群組設定</el-menu-item>
            </el-submenu>
            <el-submenu index="10">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appReportquery')}}</template>
              <el-menu-item index="adminReportIndex" @click="trans('adminReportIndex')">營收統計</el-menu-item>
              <el-menu-item index="AdminRevenueAnalyze" @click="trans('adminRevenueAnalyze')">營收分析</el-menu-item>
              <el-menu-item index="adminClientAnalyze" @click="trans('adminClientAnalyze')">客戶畫像統計</el-menu-item>
              <el-menu-item index="RentingAnalyze" @click="trans('RentingAnalyze')">租借分析</el-menu-item>
              <el-menu-item v-if="f2.auth.userType=='0'" index="CTRAnalyze" @click="trans('CTRAnalyze')">關鍵字分析</el-menu-item>
            </el-submenu>
            <el-submenu index="4" v-if="f2.auth.userType!='3'">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appPricingsetting')}}</template>
              <el-menu-item index="adminProdPrice" @click="trans('adminProdPrice')">{{$t('appPricingplansetting')}}</el-menu-item>
              <el-menu-item index="AdminProdStock" @click="trans('AdminProdStock')">{{$t('appCarmodelsettings')}}</el-menu-item>
              <el-menu-item index="AdminPromotion" @click="trans('AdminPromotion')" >{{$t('appCouponsettings')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appVehiclesettings')}}</template>
              <el-menu-item index="adminProdCat" @click="trans('adminProdCat')" v-if="f2.auth.userType=='0'">{{$t('appModelsetting')}}</el-menu-item>
              <el-menu-item index="adminProd" @click="trans('adminProd')">{{$t('appVehicledata')}}</el-menu-item>
              <el-menu-item v-if="f2.auth.userType=='0'" index="adminTracker" @click="trans('adminTracker')">{{$t('appTrackerSetting')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appAccountsettings')}}</template>
              <el-menu-item index="adminUser" @click="trans('adminUser')" v-if="f2.auth.userType=='0'">{{$t('appRegionalmanagersettings')}}</el-menu-item>
              <el-menu-item index="adminLabor" @click="trans('adminLabor')" v-if="f2.auth.userType!='3'">{{$t('appStaffsettings')}}</el-menu-item>
              <el-menu-item index="adminSelf" @click="trans('adminSelf')" >{{$t('appUsersettings')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appCustomermanagement')}}</template>
              <el-menu-item index="ClientUserApplyCancelForm" @click="trans('ClientUserApplyCancelForm')" v-if="f2.auth.userType=='0'">{{$t('appClientUserApplyCancelForm')}}</el-menu-item>
              <el-menu-item index="memberCreditRule" @click="trans('memberCreditRule')" v-if="f2.auth.userType=='0'">{{$t('appMemberCreditRule')}}</el-menu-item>
              <el-menu-item index="adminClientUser" @click="trans('adminClientUser')" v-if="f2.auth.userType=='0'">{{$t('appCustomeraccountsettings')}}</el-menu-item>
              <el-menu-item index="adminUserFeedback" @click="trans('adminUserFeedback')">{{$t('appCustomerfeedback')}}</el-menu-item>
              <el-menu-item index="memberCenterAd" @click="trans('memberCenterAd')" v-if="f2.auth.userType=='0'">{{$t('appAdsettings')}}</el-menu-item>
              <el-menu-item index="lineMessage" @click="trans('lineMessage')" v-if="f2.auth.userType=='0'">Line Message</el-menu-item>
              <el-menu-item index="adminTrafficTicket" @click="trans('adminTrafficTicket')" v-if="f2.auth.userType=='0'">罰單轉責</el-menu-item>
            </el-submenu>
            <el-submenu index="12" v-if="f2.auth.userType !=='3'">
              <template slot="title"><i class="el-icon-menu"></i>帳務中心</template>
              <!-- <el-menu-item index="financialWSTP" @click="trans('financialWSTP')">文中</el-menu-item> -->
              <el-menu-item index="FinancialWallet" @click="trans('FinancialWallet')">零用金</el-menu-item>
              <el-menu-item index="storeMoneyAccountSetting" @click="trans('storeMoneyAccountSetting')">資金帳戶設定</el-menu-item>
              <!-- <el-menu-item index="clientUserAtmCheck" @click="trans('clientUserAtmCheck')">{{$t('appClientUserAtmCheck')}}</el-menu-item> -->
              <el-menu-item index="financialClientOrderPayQuery" @click="trans('financialClientOrderPayQuery')">收款查詢</el-menu-item>
              <el-menu-item index="financialInvoiceQuery" @click="trans('financialInvoiceQuery')">發票查詢</el-menu-item>
              <el-menu-item index="FinancialRefundQuery" @click="trans('FinancialRefundQuery')">應退帳款</el-menu-item>
              <!-- <el-menu-item index="financialRefund" @click="trans('financilaRefund')">退款作業(開發中)</el-menu-item> -->
            </el-submenu>
            <el-submenu index="8" v-show="false">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appFrontdesk')}}</template>
              <el-menu-item index="8-1" @click="trans('login')">{{$t('appLogin')}}</el-menu-item>
              <el-menu-item index="8-2" @click="trans('clientBackIndex')">{{$t('appBackgroundhome')}}</el-menu-item>
              <el-menu-item index="8-3" @click="trans('register')">{{$t('appPickupprocess')}}</el-menu-item>
              <el-menu-item index="8-4" @click="trans('register')">{{$t('appReturnprocess')}}</el-menu-item>
              <el-menu-item index="9-1" @click="trans('selectProd')">{{$t('appChoosecar')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="10"  v-show="false">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appEmailAd')}}</template>
              <el-menu-item index="9-1" @click="trans('emailSend')">{{$t('appEmailSend')}}</el-menu-item>
            </el-submenu>
            <el-submenu index="11">
              <template slot="title"><i class="el-icon-menu"></i>{{$t('appEvent')}}</template>
              <el-menu-item index="adminAlert" @click="trans('adminAlert')">{{$t('appAlert')}}</el-menu-item>
              <el-menu-item index="clientOrderChangeLog" @click="trans('/clientOrderChangeLog')" v-if="f2.auth.userType=='0'">訂單更改紀錄</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>      
        <el-main :class="f2.auth.login ? 'mainColor':'mainImage'">
          <!-- <keep-alive include="Home"> -->
            <router-view></router-view>
          <!-- </keep-alive> -->
        </el-main>
        <!-- <el-footer style="text-align: center; font-size: 12px ;">
          <span>王小虎</span>
          <el-button type="success" @click="postSpringBoot()">成功按钮</el-button>
        </el-footer> -->
      </el-container>
    </el-container>
    <div v-on:gotoMenu="setMenu"> </div>
  
  </div>
</template>
<style rel="stylesheet/scss" lang="scss" scoped>
.mainColor {
  background-color: rgb(236, 251, 255);
}
.mainImage {
  background-image: url("./assets/backSideLoginBackGround.jpg") ;
}
</style>
<style  scoped>
.loginMain{
  background-color: rgb(236, 251, 255);
}
.el-submenu__title:focus,
.el-submenu__title:hover {
 outline:0;
 background-color:#00499c
}
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: rgb(98, 87, 87);
  }

.el-main{
  background-color: rgb(236, 251, 255);
  /* background-image: url("./assets/backSideLoginBackGround.jpg") ; */
}
  .item {
  /* margin-top: 10px; */
  margin-right: 40px;
}

</style>
<script>
import router from './router'
  export default {
    data() {
      return {
        titleAppend:'',
        // menuPath:'adminProdStatus',
        // openlist: ['9'],
        colortw:'',
        coloren:'',
        menuOpen: true,
        phone: false
      }
    },
    created(){
      // this.menuPath=this.$router.currentRoute.path.substring(1),
      // this.lang = 'en',
      if (window.location.href.indexOf('https://cms.mofuntravel.com.tw') ===-1) {
        this.titleAppend = '測試機!'
      }
      this.lang = process.env.VUE_APP_I18N_LOCALE
      let win = window,
          doc = document,
          docElem = doc.documentElement,
          body = doc.getElementsByTagName('body')[0],
          x = win.innerWidth || docElem.clientWidth || body.clientWidth
      this.menuOpen = x >768
      this.phone = x <= 768
      if(this.$route.path ==='/onePageGet' || this.$route.path ==='/onePageReturn') {
        this.menuOpen = false
        this.phone = true
      }
      // this.colortw = 'info'
      // this.changelangcolor()
    },
    computed:{
      f2(){
        return {
          auth:{
              f2:this.$store.getters.f2,
              login:this.$store.getters.login,
              disName:this.$store.getters.disName,
              userType:this.$store.getters.userType
          }
        }
      },
      onmenuPath() {
                // console.log(this.$route.path);
                return this.$route.path.replace('/','');
      }
    },
    methods :{
      setMenu(menuPath){
        console.log('get it')
        this.menuPath=menuPath;
      },
      logout(){
        this.$axios
                .get('../api/logout',this.loginClientUser)
                .then((res)=>{
                   
                  // console.log(res);
                  if(res.data.authority!=null){
                     this.$store.dispatch('logout')
                    // Cookies.set('clientUser', res.data.clientUser);
                    // if(this.$store.state.loginNextPage!=''){
                    //   this.$router.push('clientBackIndex');
                    // }else{
                    //   this.$router.push('clientBackIndex');
                    // }
                  }
                  window.location = "./"
              }).catch((res)=>{
                  console.log(res.response.status)
                  this.$alert('login error','注意',{
                    confirmButtonText:'確定'
                  })
              });        
       
      },
      changelangtw() {
        if ( this.lang === 'en' ) {
            this.lang = 'tw';          
            this.$i18n.locale = this.lang; 
            this.colortw = 'info';  
            this.coloren = 'info'+'plain';         
        }
      },
      changelangen() {
        if ( this.lang === 'tw' ) {
            this.lang = 'en';          
            this.$i18n.locale = this.lang;
            this.coloren = 'info';  
            this.colortw = 'info'+'plain';     
        }
      },
      changelangcolor(){
        if(this.lang == 'tw'){
          this.colortw = 'info';  
          this.coloren = 'info'+'plain';  
        }else if(this.lang =='en'){
          this.coloren = 'info';  
          this.colortw = 'info'+'plain';
        }
      },
      login(){
        this.$store.dispatch('login')
      },      
      trans  (path) {
        if(path == '/onePageGet'|| path == '/onePageReturn' || path == '/adminProdLocation') {
          this.phone = true
          this.menuOpen = false
        }
        router.push(path);
        
      },
      changeAuthFromVuex(){
        window.location='/'
      },
      getA () {
          this.$axios
        .get('https://api.coindesk.com/v1/bpi/currentprice.json')
        .then(function(res){
          console.log(res.data.bpi);
        });
        this.$store.commit('increment')
        console.log(this.$store.state.count)
        this.$store.state.menuopens=['4']

        
      },
      postSpringBoot () {
          this.$axios
        .post('http://localhost:8081/login',{ account: 'user',password:'123' })
        
        .then(function(res){
          console.log(res.data);
        });
        // this.$store.commit('increment')
        // console.log(this.$store.state.count)
        // this.$store.state.menuopens=['4']
      },
      getSpringBoot () {
          this.$axios
        .get('http://localhost:8081/user/greeting')
        .then(function(res){
          console.log(res.data);
        });
        // this.$store.commit('increment')
        // console.log(this.$store.state.count)
        // this.$store.state.menuopens=['4']
      }               
    }
  };
</script>
