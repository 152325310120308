<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-form :model="form">
        <h2>一頁式取車</h2>
        <el-row>
          <el-col :span="6" class="storIdSpan">
            <el-select v-model="form.storeId" placeholder="" @change="getprods">
              <el-option width="120" v-for="item in stores" :key="item.code" :label="item.disName" :value="item.code"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="身份證字號">
              <el-input type="text" @change="check_num(form.clientUserId)" v-model="form.clientUserId" @blur="checkClientUserId()" style="width:225px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="會員名稱">
              <el-input type="text" v-model="form.clientUserDisName" @blur="checkClientUserDisName()" style="width:225px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期">
              <el-date-picker style="width:225px" v-model="form.birthday" :clearable="false" :editable="false" type="date" placeholder="出生日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8" v-show="false">
            <el-form-item label="性別：">
              <el-select v-model="form.sex" style="width:90px">
                <el-option key="1" value="1" label="男"></el-option>
                <el-option key="2" value="2" label="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手機(預設為密碼):">
              <el-input type="text" v-model="form.phone" @blur="checkClientUserPhone()" style="width:225px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <span v-show="isVerify">已通過驗證</span>
            <el-form-item label="手機驗證碼:" v-show="!isMember || !isVerify">
              <el-button size="mini" @click="sentVerifyCode" :disabled="coldDown > 0">
                發送驗證碼<span v-show="coldDown > 0">{{ coldDown }}秒</span></el-button>
              <el-input type="text" v-model="form.phoneToken" style="width:150px"></el-input>
              <el-button size="mini" @click="verifyAccount('phone')">驗證</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Email:">
              <el-input type="text" v-model="form.email" style="width:225px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <span v-show="isVerify">已通過驗證</span>
            <el-form-item label="Email驗證碼:" v-show="!isMember || !isVerify">
              <el-button size="mini" @click="sentEmailVerifyCode" :disabled="coldDown > 0">
                發送驗證碼<span v-show="coldDown > 0">{{ coldDown }}秒</span></el-button>
              <el-input type="text" v-model="form.emailToken" style="width:150px"></el-input>
              <el-button size="mini" @click="verifyAccount('email')">驗證</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" style="font-size:12px">
          <el-checkbox v-model="isMotoLicense"  @change="getprods" />我擁有重型機車駕照
        </el-row>
        <el-row :gutter="10" style="font-size:12px">
          <el-checkbox v-model="agreeMemberRule" />我已閱讀並同意
          <span style="font-size:16px;color:blue" @click="ruleDialog = !ruleDialog">會員與隱私條款</span>
        </el-row>
        <el-row :gutter="10" style="font-size:12px">
          <el-checkbox v-model="agreeStoreRule" />我已閱讀並同意
          <span style="font-size:16px;color:blue" @click="showStoreRule">租貸條款</span>
        </el-row>
        <el-row :gutter="10">
          <h3>證件資料</h3>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('pic1')">
              <span v-show="pic1 == ''">身分證正面</span>
              <img class="photo" :src="pic1" style="display:block; margin:auto;" v-show="pic1 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('pic2')">
              <span v-show="pic2 == ''">身分證背面</span>
              <img class="photo" style="display:block; margin:auto;" :src="pic2" v-show="pic2 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('pic3')">
              <span v-show="pic3 == ''">駕照正面</span>
              <img class="photo" style="display:block; margin:auto;" :src="pic3" v-show="pic3 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('pic4')">
              <span v-show="pic4 == ''">駕照背面</span>
              <img class="photo" style="display:block; margin:auto;" :src="pic4" v-show="pic4 != ''" />
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <h3>預約資訊</h3>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4" style="margin-top:2px">
            租貸期間(起):
          </el-col>
          <el-col :span="2">
            <el-checkbox v-model="isRentRightNow" style="margin-top:6px">現在</el-checkbox>
          </el-col>
          <el-col :span="7">
            <span v-html="'<p></p>'" v-show="isRentRightNow"></span>
            <el-date-picker type="datetime" style="width:180px" size="mini" :clearable="false" 
            v-model="form.sDate" v-show="!isRentRightNow" @change="changeEDate()"></el-date-picker>
          </el-col>
          <el-col :span="4" style="margin-top:2px">
            租貸期間(迄):
          </el-col>
          <el-col :span="7">
            <el-date-picker type="datetime" style="width:180px" size="mini" :clearable="false"
              v-model="form.eDate" @change="changeEDate()"></el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px">
          <el-col :span="13" v-if="form.clientOrderId != '' && form.clientOrderId != null">
            訂單號:{{ form.clientOrderId }}
          </el-col>
          <el-col :span="13" v-else>
            新訂單
          </el-col>
          <el-col :span="11" style="margin-top:8px">
            <el-button type="primary" @click="changeEDate('12')" size="mini">12小時</el-button>
            <el-button type="primary" @click="changeEDate('24')" size="mini">一天</el-button>
            <el-button type="primary" @click="changeEDate('48')" size="mini">兩天</el-button>
            
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4">
            <h3>
              車輛清單
            </h3>
          </el-col>
          <el-col :span="4">
            <el-input type="tel" placeholder="車號查詢" style="width:100px;margin-top:10px" @input="serchProd" v-model="qCarNo"></el-input>
          </el-col>
          <el-col :span="8" v-if="form.clientOrderId != '' && form.clientOrderId != null" style="margin-top:18px">
            預約車型： {{form.descTitle}}
          </el-col>
        </el-row>
        <el-row :gutter="10">
          目前選擇：{{ this.form.carNoDisName }}
          <table style="display: block;overflow-x: auto;white-space: nowrap;">
            <td v-for="car in showProds" :key="car.prodId" style="width:150px;" @click="selectProd(car)">
              <el-card style="text-align:center" :class="selected == car.prodId ? 'selectit' : 'empty'">
                <img :src="car.officeImg" style="width:150px;height:100px" />
                <p style="text-align:center">{{ car.carNoDisName }}</p>
                <p style="text-align:center" v-show="car.enabledDistance == 'T'">
                  目前里程{{ car.nowDistance }}</p>
              </el-card>
            </td>
          </table>
          <div v-show="showProds.length == 0" style="color:#bfbfbf">
            搜尋不到車輛
          </div>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px">
          <el-col :span="3" style="margin-top:8px">
            隨車配件:
          </el-col>
          <el-col :span="6">雨衣：
            <el-select v-model="form.raincoat" style="width:85px">
              <el-option key="0" value="0" label="0"></el-option>
              <el-option key="1" value="1" label="1"></el-option>
              <el-option key="2" value="2" label="2"></el-option>
              <el-option key="3" value="3" label="3"></el-option>
              <el-option key="4" value="4" label="4"></el-option>
              <el-option key="5" value="5" label="5"></el-option>
            </el-select>
          </el-col>
          <el-col :span="7">半罩安全帽：
            <el-select v-model="form.helmet" style="width:85px">
              <el-option key="0" value="0" label="0"></el-option>
              <el-option key="1" value="1" label="1"></el-option>
              <el-option key="2" value="2" label="2"></el-option>
              <el-option key="3" value="3" label="3"></el-option>
              <el-option key="4" value="4" label="4"></el-option>
              <el-option key="5" value="5" label="5"></el-option>
            </el-select>
          </el-col>
          <el-col :span="7">3/4安全帽：
            <el-select v-model="form.helmetFull" style="width:85px">
              <el-option key="0" value="0" label="0"></el-option>
              <el-option key="1" value="1" label="1"></el-option>
              <el-option key="2" value="2" label="2"></el-option>
              <el-option key="3" value="3" label="3"></el-option>
              <el-option key="4" value="4" label="4"></el-option>
              <el-option key="5" value="5" label="5"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px">
          <el-col :span="12">
            <el-card>
              <el-row>
                <h3>建議費用明細</h3>
                <el-row :gutter="10" style="margin-top:5px">
                  <el-input type="number" v-model="form.price" style="width:200px" @change="changePrice">
                    <template slot="prepend">租金費用</template>
                  </el-input>
                </el-row>
                <el-row :gutter="10" style="margin-top:5px">
                  <el-input type="number" v-model="form.beforeDiscount" style="width:200px" @change="changePrice">
                    <template slot="prepend">預付折扣</template>
                  </el-input>
                </el-row>
                <el-form-item label="費用小計:" :label-width="formLabelWidth" class="title">
                  <div style="font-size:150%;">
                    &emsp;<span style="margin-left:20px;">
                      {{ this.form.price - this.form.beforeDiscount }}元</span>
                  </div>
                  <div v-show="isPriceChange == 'F'" :class="priceDetail.class" 
                  v-for="(priceDetail, index) in form.priceList" :key="index" class="form-font">
                    <span v-show="index === 1 || index === 0" style=""></span>
                    {{ priceDetail.name }}
                    <span style="">{{ priceDetail.amt }}元</span>
                  </div>
                </el-form-item>
                <el-form-item v-show="form.deposit > 0" label="預付訂金:" :label-width="formLabelWidth" class="title red-title" >
                  <span class="form-font">{{ form.deposit * -1 }}元</span>
                </el-form-item>
                <el-form-item v-show="form.beforePrice > 0" label="取車預付:" :label-width="formLabelWidth" class="title red-title" style="color:red">
                  <span class="form-font">{{ form.beforePrice * -1 }}元</span>
                </el-form-item>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-row style="margin-top:10px">
          <el-input type="text" v-model="form.reserveNote">
            <template slot="prepend">員工備註</template></el-input>
        </el-row>
        <el-row>
          <el-col :span="5">
            付款方式
            <el-radio>現金</el-radio>
          </el-col>
          <el-col :span="10">
            <el-checkbox v-model="form.plusInsurance" v-show="form.isSaleInsurance == 'T'">加購保障</el-checkbox>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <h3>車輛拍攝</h3>
        </el-row>

        <el-row>    <!--函數未刪除[參考上面拍攝]-->
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat1')">
              <span v-show="picat1 == ''">車輛照片</span>
              <img class="photo" :src="picat1" style="display:block; margin:auto;" v-show="picat1 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat2')">
              <span v-show="picat2 == ''">車輛照片</span>
              <img class="photo" style="display:block; margin:auto;" :src="picat2" v-show="picat2 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat3')">
              <span v-show="picat3 == ''">車輛照片</span>
              <img class="photo" style="display:block; margin:auto;" :src="picat3" v-show="picat3 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat4')">
              <span v-show="picat4 == ''">車輛照片</span>
              <img class="photo" style="display:block; margin:auto;" :src="picat4" v-show="picat4 != ''" />
            </div>
          </el-col>
        </el-row>

        <el-row>
          <!-- init -->
        </el-row>
        <el-row style="text-align:center">
          簽名區
        </el-row>
        <el-row>
          <div id="signBord" style="background:#edcb46;width:800px;height:350px;display:block; margin:auto;">
            <VueSignaturePad width="800px" height="350px" ref="signaturePad" />
          </div>
        </el-row>

        <el-row :gutter="10" style="margin-top:10px">
          <div style="float:right;">
            <el-button type="primary" style="font-size:150%;" @click="clear()" >清除簽名</el-button>
            <el-button type="primary" v-show="form.beforePay === 'F'" @click="confirmStartRent()" style="font-size:150%;">開始租車</el-button>
            <el-button type="primary" v-show="form.beforePay === 'T'" @click="confirmStartRentAndBeforePay()" style="font-size:150%;" >會員現金預付和開始租車</el-button>
            <el-button type="primary" style="font-size:150%;" @click="to('/adminProdStatus')" >回系統首頁</el-button>
            <el-button type="primary" @click="to('/onePageReturn')" style="font-size:150%;" >一頁式還車</el-button>
          </div>
        </el-row>
      </el-form>
    </el-card>

    <el-dialog :visible.sync="ruleDialog" :close-on-click-modal="false" font-size="150%" width="90%" center title="會員與隱私條款">
      <!-- 條款 -->
      <div class="terms-of-service">
        <!-- 條款內容 -->
        MOFUN非常重視會員個人資料之保護，為了提供會員安心消費的環境，MOFUN對於會員資料的蒐集、處理、利用、保護均遵守中華民國「個人資料保護法」(以下稱「個資法」)特定目的及相關法令規範。<br />
        MOFUN
        服務為摩拓旅程股份有限公司及其關係企業與／或子公司（以下合稱為「MOFUN」或「我們」）所提供。為會員提供本服務時，就MOFUN現有或日後自會員取得之會員個人資料，得依法蒐集、處理、利用與國際傳輸。由於個人資料之蒐集，涉及會員之隱私權益，MOFUN特此依據個資法第八條第一項規定，告知會員下列事項：（一）蒐集之目的（二）個人資料之類別（三）MOFUN得蒐集、處理及利用的個人資訊（四）會員依個人資料保護法得行使之權利及方式（五）MOFUN
        隱私權政策的變更<br />
        請您在開始使用 MOFUN
        服務前，充分、仔細閱讀以下隱私權政策（下稱「本政策」）。本政策構成 MOFUN
        使用條款之一部分，為您使用 MOFUN
        服務時必須遵守的規定。您瞭解並同意，當您開始使用 MOFUN
        服務時，即表示您已同意本政策的內容。若您不同意本政策任何內容，請勿使用
        MOFUN 服務。<br />
        <br />
        <p>(1)蒐集之目的</p>
        依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，MOFUN蒐集、處理、利用及保有會員個人資料之特定目的列示如下：<br />
        ○三九 行政裁罰、行政調查<br />
        ○四○ 行銷（包含金控共同行銷業務）<br />
        ○六三 非公務機關依法定義務所進行個人資料之蒐集處理及利用<br />
        ○六九 契約、類似契約或其他法律關係事務<br />
        ○七七 訂位、住宿登記與購票業務<br />
        ○九○ 消費者、客戶管理與服務<br />
        ○九一 消費者保護<br />
        ○九三 財產保險<br />
        ○九四 財產管理<br />
        ○九八 商業與技術資訊<br />
        一○四 帳務管理及債權交易業務<br />
        一一一 票券業務<br />
        一三六 資（通）訊與資料庫管理<br />
        一三七 資通安全與管理<br />
        一四八 網路購物及其他電子商務服務<br />
        一五二 廣告或商業行為管理<br />
        一五三 影視、音樂與媒體管理<br />
        一五七 調查、統計與研究分析<br />
        一七○
        觀光行政、觀光旅館業、旅館業、旅行業、觀光遊樂業及民宿經營管理業務<br />
        一七二
        其他公共部門（包括行政法人、政府捐助財團法人及其他公法人）執行相關業務<br />
        一七三 其他公務機關對目的事業之監督管理<br />
        一七六 其他自然人基於正當性目的所進行個人資料之蒐集處理及利用<br />
        一八一 其他經營合於營業登記項目或組織章程所定之業務<br />
        <br />
        <p>(2)個人資料之類別</p>
        MOFUN因本服務將蒐集會員之個人資料計有：<br />
        A、識別類 C001 至
        C003（如姓名、電話、銀行帳戶號碼、信用卡號碼、身分證統一編號等）<br />
        B、特徵類 C011 至 C014（如年齡、性別、出生年月日等）<br />
        C、社會情況 C031 至
        C041（如旅行及其他遷徙細節、住所地址、所有之動產或不動產、護照、工作許可文件、嗜好等）<br />
        D、Ｃ066
        健康與安全紀錄(如：職業疾病、安全、意外紀錄、急救資格、旅外急難救助資訊等。<br />
        E、財務細節：Ｃ082負債與支出(如：支出總額、租金支出、貸款支出、本票等信用工具支出等。)、Ｃ091資料主體所取得之財貨或服務(如：貨物或服務之有關細節、資料主體之貸款或僱用等有關細節等。)、C093財務交易(如：收付金額、信用額度、保證人、支付方式、往來紀錄、保證金或其他擔保等。)、C094賠償(如：受請求賠償之細節、數額等)。<br />
        F、商業資訊：Ｃ101資料主體之商業活動(如：商業種類、提供或使用之財貨或服務、商業契約等。)、Ｃ102約定或契約(如：關於交易、商業、法律或其他契約、代理等。)<br />
        G、其他各類資訊 C131 至 C133（如未分類之資料、電子郵件等） <br />
        <br />
        <p>(3)MOFUN得蒐集、處理及利用的個人資訊</p>
        當您申請註冊 MOFUN
        服務時，為了確認您的身分及使用資格、寄發確認函及其他管理訊息、通知您本政策或其他使用條款之變更、瞭解您的問題及需求、會員管理等目的，我們將蒐集您的基本資料，包括但不限於您的姓名、出生年月日、聯絡電話、郵遞地址及電子郵件信箱等，並要求您上傳身分證明文件及駕照正反面影本。
        當您使用 MOFUN 服務時，為了提供您完整的服務，並持續改進 MOFUN
        服務、優化用戶體驗等目的，我們將蒐集您使用 MOFUN
        服務時所產生的相關紀錄，包括但不限於裝置資訊、系統偏好設定、即時位置資訊、租借時間、騎乘路線、消費明細、行駛里程、交通違規紀錄、罰鍰繳納情形、意見回饋等。
        當會員在註冊個人帳戶並提供個人資料以取得本服務時，MOFUN會蒐集會員之個人資料作為金融交易及信用卡授權、使用者管理與客服服務、行銷（含抽獎活動及以電子、書面、電話、電信、網際網路及其他方式於蒐集之特定目的範圍內處理並利用消費者個資進行合作廠商商品之宣傳行銷）、公益勸募、市場調查、消費者消費習慣調查及其他合於營業登記項目業務的經營需要，或觀察會員瀏覽行為的特徵、蒐集消費記錄，進行使用者分群與瀏覽模式分析，提供專屬會員之個人化服務，並將資料提供給MOFUN、總公司、各國/地區分公司及所委任處理營業相關事務之第三人（例如：各地代理商、維修店家/廠商、合作店家/廠商、信用卡收單銀行、付款信用卡發卡銀行、廣告服務商、第三方支付服務提供者等），依照前述服務目的明定內容使用或揭露客戶基本資料、帳務資料或其他資料。除非另有法令規範，或另行取得會員之同意，否則MOFUN不會向前述以外之第三人揭露會員之個人資料。
        法院、檢調單位、公路監理機關、警察機關或其他行政機關，基於法令、命令、裁判、處分或其他法定調查程序，要求
        MOFUN
        提供您的個人資訊時，我們將依法配合。為免除您生命、身體、自由或財產上之危險，或公務機關或學術研究機構基於公共利益為統計或學術研究而有必要時，MOFUN
        亦得依法利用您的個人資訊。 若因您違反 MOFUN 使用條款或，而造成 MOFUN
        或任何第三人死亡、人身或財產上損害（失），或在您租借期間內發生任何違反道路交通管理事件，MOFUN
        為進行相關法律程序、請求賠（補）償或向公路監理機關逕行舉發，亦得依法利用您的個人資訊。
        MOFUN 合作車行或廠商（下稱「合作廠商」）提供 MOFUN
        服務之任何部分時，依法得在原蒐集目的及提供 MOFUN
        服務之必要範圍內，將您的個人資訊提供予合作廠商。我們將促使合作廠商遵守本政策，並確保合作廠商恪遵個資保護相關法令，以落實我們對保護您的隱私所作的承諾。
        <br />
        <p>(4)會員依個人資料保護法得行使之權利及方式</p>
        會員可以檢具身份證明資料等文件，向MOFUN行使以下權利並且透過
        service@zocha.com.tw
        與我們聯繫，請求閱覽、查詢、複製、補充、更正您個人資訊，並得請求停止蒐集、處理或利用或刪除您的個人資訊。但多次查詢或請求閱覽個人資料或製給複製本者，MOFUN得酌收必要成本費用每件NT100元。如因法律有特別規定或為MOFUN執行業務所必須者、爭議帳戶或其行為將影響第三人者，MOFUN得不依申請為之。
        若您欲刪除或請我們停止蒐集、處理或利用您的個資，MOFUN將無法繼續提供服務。您可透過電子信箱
        ( service@zocha.com.tw
        )、電話(02-26559021)或線上客服讓我們知道您想要刪除的個人資料，我們將以電子信件回覆並提供您
        「個資刪除申請書」。請填寫後並郵寄至「台北市萬華區康定路 46-4 號 1 樓
        摩拓旅程股份有限公司收」。我們在收到您的申請書後將會以電子郵件通知您，並暫時停用此帳號。經確認帳號無尚未繳納餘額後（如服務費用或交通罰單等），依照相關個人資料保護法處理。MOFUN將採取適當個資維護措施，防止您的個資被竊取、竄改、毀損、滅失或洩漏，並要求所有員工恪遵相關政策及安全準則，確實履行對保護您的隱私所作的承諾。但您瞭解並同意，世界上任何系統或技術皆無法保證個人資訊絕對安全。若您發現
        MOFUN 服務系統有任何漏洞，請透過 service@zocha.com.tw 與我們聯繫。
        <br />
        <p>(5)MOFUN 隱私權政策的變更</p>
        MOFUN
        保留權利，但無義務得隨時不經通知自行決定增加、變更、編輯、修正、修訂、修改或刪除本政策任何內容。除本政策另有規定者外，本政策更新後的版本將於公告日起生效。您應定期檢閱本政策，以確認其有無變更。若您在本政策更新版本公告後繼續使用
        MOFUN 服務，視為您已瞭解並同意本政策之變更。更新日：2021 年 5 月 20 日
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="agreeMember" type="primary">同意上述內容</el-button>
        <el-button @click="ruleDialog = false">返回</el-button>
      </div>
      <el-form> </el-form>
    </el-dialog>

    <el-dialog :visible.sync="storeRuleDialog" :close-on-click-modal="false" font-size="150%" width="90%" center title="租貸條款">
      <!-- 店家條款 -->
      <div class="terms-of-service" v-html="content"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="agreeStore" type="primary">同意上述內容</el-button>
        <el-button @click="storeRuleDialog = false">返回</el-button>
      </div>
      <el-form> </el-form>
    </el-dialog>
    <el-dialog title="發現會員已有訂單" :visible.sync="clientOrdersDialog" :close-on-click-modal="false" font-size="150%" width="500px" center>
      <el-row>
        <el-col :span="8"> 會員：{{ form.clientUserId }} </el-col>
        <el-col :span="8"> 姓名：{{ form.clientUserDisName }} </el-col>
        <el-col :span="8"> 電話：{{ form.phone }} </el-col>
      </el-row>

      <div style="margin-top:4px;padding: 5px 5px" v-for="co in clientOrders" :key="co.clientOrderId" :ref="co.clientOrderId">
        <el-card>
          <div>
            <h4>訂單號碼-{{ co.clientOrderId }}</h4>
          </div>
          <div>
            <ul>
              <li><span>車型：</span>
                <span>{{ co.descTitle }}</span></li>
              <li><span>租借起始時間：</span>
                <span>{{ co.eDate }}</span></li>
              <li><span>租借結束時間：</span>
                <span>{{ co.eDate }}</span></li>
              <li><span>訂單狀態：</span>
                <span>{{ co.flow_ }}</span></li>
            </ul>
          </div>
          <div>
            <el-button @click="selectOrder(co)">選擇</el-button><span v-show="co.flow != '50'">此訂單{{ co.flow_ }}，選擇此訂單將不收取訂金</span>
          </div>
        </el-card>
      </div>
      <!-- 用戶訂單 -->
      <el-form> </el-form>
      <div slot="footer" class="dialog-footer">
        找不到?
        <el-button @click="clientOrdersDialog = false" type="primary">建立新訂單</el-button>
      </div>
    </el-dialog>
    <form action="" method="post" ref="uploadForm1" v-show="false">
      <input type="file" accept="image/*" @change="uploadFile1" ref="fileBtn1" />
    </form>
    <form action="" method="post" ref="uploadForm2" v-show="false">
      <input type="file" accept="image/*" @change="uploadFile2" ref="fileBtn2" />
    </form>
    <form action="" method="post" ref="uploadForm3" v-show="false">
      <input type="file" accept="image/*" @change="uploadFile3" ref="fileBtn3" />
    </form>
    <form action="" method="post" ref="uploadForm4" v-show="false">
      <input type="file" accept="image/*" @change="uploadFile4" ref="fileBtn4" />
    </form>

    <form action="" method="post" ref="uploadFormCat1" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat1" ref="fileBtnCat1" />
    </form>
    <form action="" method="post" ref="uploadFormCat2" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat2" ref="fileBtnCat2" />
    </form>
    <form action="" method="post" ref="uploadFormCat3" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat3" ref="fileBtnCat3" />
    </form>
    <form action="" method="post" ref="uploadFormCat4" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat4" ref="fileBtnCat4" />
    </form>

  </div>
</template>


<script>
import imageCompression from "browser-image-compression";
import * as moment from "moment/moment";
export default {
  data() {
    return {
      fullscreenLoading: false,
      clientOrdersDialog: false,
      ruleDialog: false,
      storeRuleDialog: false,
      content: "",
      coldDown: 0,
      agreeMemberRule: false,
      agreeStoreRule: false,
      clientOrders: [],
      pic1: "",
      pic2: "",
      pic3: "",
      pic4: "",
      picat1: "",
      picat2: "",
      picat3: "",
      picat4: "",
      isMember: false,
      isVerify: false,
      isRentRightNow: true,
      timer: null,
      prods: [],
      showProds: [],
      qCarNo: "",
      selectedCar: null,
      sysTime: "",
      prodStocks: [],
      form: {
        clientOrderId: "",
        tempClinetOrderId: "",
        photoNo:0,
        beforeDiscount: 0,
        clientUserId: "",
        clientUserDisName: "",
        birthday: undefined,
        adminNote: "",
        phone: "",
        areaId: "",
        carNo: "",
        storeId: "",
        sDate: "",
        eDate:"",
        name: "",
        region: "",
        date1: "",
        date2: "",
        type: [],
        resource: "",
        desc: "",
        plusInsurance: true,
        emptyCat: "",
        priceList: [],
        reserveNote: "",
        rentDateType: 1,
        helmetFull: "0",
        helmet: "1",
        raincoat: "0",
        price: null,
        beforePay: "F",
        isSaleInsurance: "F",
        descTitle: '',
        email: '',
        phoneToken: '',
        emailToken: ''
      },
      formLabelWidth: "139px",
      stores: [],
      isCreate: false,
      isCal: false,
      signBord: null,
      touchMonveHandle: null,
      selected: "",
      isPriceChange: "F",
      isMotoLicense: true,
      imageOption: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      },
    };
  },
  created() {
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        res.data.authStores.forEach(e => {
          this.stores.push(e);
        });
        this.form.storeId = this.stores[0].code;
        this.$store.dispatch("login", res.data);
        this.$axios.get("../api/booking/getSysTime", {}).then(res => {
            this.sysTime = res.data;
            this.init();
          }).catch(() => {});
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  name: "OnePageGet",
  components: {},
  computed: {},
  methods: {

    checkClientOrderId(){
      if(this.form.clientOrderId == ""){
        if(this.tempClinetOrderId == "" || this.tempClinetOrderId == null){
         this.tempClinetOrderId = + Math.floor(Date.parse(this.sysTime)) + Math.floor(Math.random() * 1000);
        }
      }else{
        this.tempClinetOrderId = this.form.clientOrderId ;
      }
    },

    async uploadFile1(e) {
      this.formData1 = new FormData();
      this.uploadPath = "../api/adminClientUser/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formData1.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      this.pic1 = "";
      let tUploadPath = this.uploadPath + this.form.clientUserId + "/1";
      await this.$axios.post(tUploadPath, this.formData1).then(res => {
          this.pic1 = "../api/adminClientUser/getUserPic/clientUser/" + res.data.img;
          this.$refs.uploadForm1.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFile2(e) {
      this.formData2 = new FormData();
      this.uploadPath = "../api/adminClientUser/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formData2.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath + this.form.clientUserId + "/2";
      this.pic2 = "";
      await this.$axios.post(tUploadPath, this.formData2).then(res => {
          this.pic2 = "../api/adminClientUser/getUserPic/clientUser/" + res.data.img;
          this.$refs.uploadForm2.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFile3(e) {
      this.formData3 = new FormData();
      this.uploadPath = "../api/adminClientUser/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formData3.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath + this.form.clientUserId + "/3";
      this.pic3 = "";
      await this.$axios.post(tUploadPath, this.formData3).then(res => {
          this.pic3 = "../api/adminClientUser/getUserPic/clientUser/" + res.data.img;
          this.$refs.uploadForm3.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFile4(e) {
      this.formData4 = new FormData();
      this.uploadPath = "../api/adminClientUser/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formData4.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath + this.form.clientUserId + "/4";
      this.pic4 = "";
      await this.$axios.post(tUploadPath, this.formData4).then(res => {
          this.pic4 ="../api/adminClientUser/getUserPic/clientUser/" + res.data.img;
          this.$refs.uploadForm4.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },

    async uploadFileCat1(e) {
      this.formDataCat1 = new FormData();
      this.uploadPath = "../api/onePageGet/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat1.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      this.picat1 = "";
      let tUploadPath = this.uploadPath +  this.tempClinetOrderId + "/1";
      await this.$axios.post(tUploadPath, this.formDataCat1).then(res => {
          this.picat1 = "../api/onePageGet/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat1.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFileCat2(e) {
      this.formDataCat2 = new FormData();
      this.uploadPath = "../api/onePageGet/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat2.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath +  this.tempClinetOrderId + "/2";
      this.picat2 = "";
      await this.$axios.post(tUploadPath, this.formDataCat2).then(res => {
          this.picat2 = "../api/onePageGet/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat2.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFileCat3(e) {
      this.formDataCat3 = new FormData();
      this.uploadPath = "../api/onePageGet/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat3.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath +  this.tempClinetOrderId + "/3";
      this.picat3 = "";
      await this.$axios.post(tUploadPath, this.formDataCat3).then(res => {
          this.picat3 = "../api/onePageGet/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat3.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFileCat4(e) {
      this.formDataCat4 = new FormData();
      this.uploadPath = "../api/onePageGet/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat4.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath +  this.tempClinetOrderId  + "/4";
      this.picat4 = "";
      await this.$axios.post(tUploadPath, this.formDataCat4).then(res => {
          this.picat4 ="../api/onePageGet/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat4.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },

    check_num(clientUserId) {
      // 限制輸入數字英文
      this.form.clientUserId = clientUserId.replace(/[^a-z/A-Z/0-9]/, "");
    },
    checkClientUserId() {
      this.form.clientUserId = this.form.clientUserId.trim();
      this.form.clientUserId = this.form.clientUserId.toUpperCase();
      if (this.form.clientUserId == "" || this.form.clientUserId == null) {
        this.$alert("請輸入有效會員帳號", "結果", {confirmButtonText: "確定"});
        return;
      }
      this.$axios
        .get("../api/booking/checkClientUserId?clientUserId=" +this.form.clientUserId,{})
        .then(res => {
          if (res.data.disName == undefined) {
            this.$alert("沒有註冊過", "結果", { confirmButtonText: "確定" });
            this.form.clientUserDisName = "";
            this.form.phone = "";
            this.form.adminNote = "";
            this.form.sex = "";
            this.form.birthday = undefined;
            this.isMember = false;
            this.isVerify = false;
            this.agreeStoreRule = false;
            this.agreeMemberRule = false;
            this.pic1 = "";
            this.pic2 = "";
            this.pic3 = "";
            this.pic4 = "";

            this.picat1 = "";
            this.picat2 = "";
            this.picat3 = "";
            this.picat4 = "";

          } else {
            if(res.data.ban != 'T') {
              this.form.clientUserDisName = res.data.disName;
              this.form.phone = res.data.phone;
              this.form.adminNote = res.data.adminNote;
              this.form.sex = res.data.sex;
              this.isMotoLicense = (res.data.motoLicense === 'M')
              this.form.birthday = moment(res.data.birthday, "YYYYMMDD");
              this.pic1 = "../api/adminClientUser/getUserPic/" + res.data.pic1;
              this.pic2 = "../api/adminClientUser/getUserPic/" + res.data.pic2;
              this.pic3 = "../api/adminClientUser/getUserPic/" + res.data.pic3;
              this.pic4 = "../api/adminClientUser/getUserPic/" + res.data.pic4;
              
              this.picat1 = "../api/adminClientUser/getOrderPic/" + res.data.picat1;
              this.picat2 = "../api/adminClientUser/getOrderPic/" + res.data.picat2;
              this.picat3 = "../api/adminClientUser/getOrderPic/" + res.data.picat3;
              this.picat4 = "../api/adminClientUser/getOrderPic/" + res.data.picat4;
              
              this.isMember = true;
              this.isVerify = true;
              this.agreeStoreRule = true;
              this.agreeMemberRule = true;
              this.getprods()
              this.checkClientUserOrder();
            } else {
              this.$alert('該帳號已被停權暫時無法租借與預約,詳細請聯繫客服','結果',{confirmButtonText: '確定'})
              this.form.clientUserId = ''
            }
          }
        }).catch(() => {});
    },
    checkClientUserDisName(){
      this.form.clientUserDisName = this.form.clientUserDisName.trim()
      if (/[~`!#$%&*+=\-\\';,/{}|\\":<>?]/g.test(this.form.clientUserDisName)) {
        this.$alert("名稱欄位請不要輸入特殊符號", "結果", {confirmButtonText: "確定"});
        return;
      }
    },
    checkClientUserPhone(){
      this.form.phone = this.form.phone.trim()
      if (/[~`!#$%&*+=\-\\';,/{}|\\":<>?]/g.test(this.form.phone)) {
        this.$alert("手機欄位請不要輸入特殊符號", "結果", {confirmButtonText: "確定"});
        return;
      }
      if (/[^\d]/.test(this.form.phone)) { 
        this.$alert("手機欄位請輸入數字", "結果", {confirmButtonText: "確定"});
        return;
      }
    },
    checkClientUserOrder() {
      this.clientOrders = Object.assign([]);
      this.form.clientOrderId = "";
      let pa = {
        clientUserId: this.form.clientUserId,
        storeId: this.form.storeId
      };
      this.$axios
        .get("../api/onePageGet/getClientOrders", { params: pa })
        .then(res => {
          if (res.data.data.length > 0) {
            res.data.data.forEach(e => {
              e.sDate = this.$date_(e.sDate);
              e.eDate = this.$date_(e.eDate);
              e.flow_ = this.$flow_(e.flow);
              this.clientOrders.push(e);
            });
            this.clientOrdersDialog = true;
          }
        });
    },
    getprods() {
      this.form.plusInsurance = false;
      this.form.prodId = "";
      this.form.carNo = "";
      this.form.carNoDisName = "";
      this.selectedCar = null;
      this.prods = Object.assign([]);
      this.showProds = Object.assign([]);
      let param = { qStoreId: this.form.storeId,motoLicense: this.isMotoLicense ? 'M':'S' };
      this.$axios
        .get("../api/onePageGet/getCarNos", { params: param })
        .then(res => {
          res.data.data.forEach(car => {
            if ((car.officeImg != null) & (car.officeImg != "")) {
              car.officeImg = "/imgs/zocha/" + car.officeImg;
            } else {
              car.officeImg = "/imgs/zocha/fontProd/none_fontProdPhoto.png";
            }
            this.prods.push(car);
            this.showProds.push(car);
          });
          this.form.beforePay = res.data.isBeforePrice;
          this.form.isSaleInsurance = res.data.isSaleInsurance;
          if (this.form.isSaleInsurance == "T") {
            this.form.plusInsurance = true;
          }
        });
    },
    init() {
      let time = Date.parse(this.sysTime);
      let d = new Date();
      d.setTime(time);
      this.form.sDate = d;
      d = new Date(d.getTime() + 24 * 1000 * 3600);
      this.form.eDate = d;
      this.getprods();
    },
    selectProd(prod) {
      this.form.prodId = prod.prodId;
      this.form.carNo = prod.carNo;
      this.form.carNoDisName = prod.carNoDisName
      this.selectedCar = prod;
      this.selected = prod.prodId;
      this.isPriceChange = "F";
      this.getPrice()
      //選擇租借車輛
    },
    serchProd() {
      this.showProds = Object.assign([]);
      this.prods.forEach(moto => {
        if (moto.carNoDisName.indexOf(this.qCarNo) !== -1 || this.qCarNo == "") {
          this.showProds.push(moto);
        }
      });
    },
    showStoreRule() {
      this.storeRuleDialog = true;
      let param = { storeId: this.form.storeId };
      this.$axios
        .get("../api/onePageGet/getStoreContract", { params: param })
        .then(res => {
          this.content = res.data.storeContract;
        });
    },
    agreeMember() {
      this.agreeMemberRule = true;
      this.ruleDialog = false;
    },
    agreeStore() {
      this.agreeStoreRule = true;
      this.storeRuleDialog = false;
    },
    selectOrder(order) {
      this.form.clientOrderId = order.clientOrderId;
      this.form.sDate = moment(order.sDate, "YYYYMMDDHHmmss");
      this.form.flow = order.flow;
      this.form.eDate = moment(order.eDate, "YYYYMMDDHHmmss");
      let now = moment(this.sysTime)
      if ( this.form.eDate < moment(this.sysTime)) {
        this.form.eDate = now.clone().add(24,'hours')
      }
      this.clientOrdersDialog = false;
      this.isRentRightNow = false;
      this.form.descTitle = order.descTitle
      // 選擇現有訂單
    },
    verifyAccount(type) {
      let pa = {
        storeId: this.form.storeId,
        clientUserId: this.form.clientUserId,
        phone: this.form.phone,
        phoneCode: this.form.phoneToken,
        email: this.form.email,
        emailCode: this.form.emailToken,
        varifyType: type,
        disName: this.form.clientUserDisName,
        birthday: moment(this.form.birthday).format("YYYYMMDD")
      };
      this.$axios.post("../api/onePageGet/createUser", pa).then(res => {
        if (res.data.rs === "success") {
          this.isMember = true;
          this.isVerify = true;
        }
      });
      // 驗證並建立新客戶資料
    },
    sentVerifyCode() {
      if (!this.agreeMemberRule || !this.agreeStoreRule) {
        this.$alert("請先閱讀並同意會員隱私條款與租貸條款", "注意", {confirmButtonText: "確定"});
        return;
      }
      this.coldDown = 600;
      this.timer = window.setInterval(() => {this.countdown();}, 1000);
      let params = {
        clientUserId: this.form.clientUserId,
        phone: this.form.phone
      };
      this.$axios
        .post("../api/onePageGet/sendPhoneValidCode", params)
        .then(res => {
          if (res.data.rs === "success") {
            this.$alert("註冊碼已發送", "注意", { confirmButtonText: "確定" });
          } else {
            this.$alert(res.data.rsMsg, "注意", { confirmButtonText: "確定" });
          }
        }).catch(() => {
          this.$alert("不准跨平台", "注意", { confirmButtonText: "確定" });
        });
      // 發送驗證碼
    },
    sentEmailVerifyCode() {
      if (!this.agreeMemberRule || !this.agreeStoreRule) {
        this.$alert("請先閱讀並同意會員隱私條款與租貸條款", "注意", {confirmButtonText: "確定"});
        return;
      }
      this.coldDown = 600;
      this.timer = window.setInterval(() => {this.countdown();}, 1000);
      let params = {
        clientUserId: this.form.clientUserId,
        email: this.form.email
      };
      this.$axios
        .post("../api/onePageGet/sendEmailValidCode", params)
        .then(res => {
          if (res.data.rs === "success") {
            this.$alert("註冊碼已發送", "注意", { confirmButtonText: "確定" });
          } else {
            this.$alert(res.data.rsMsg, "注意", { confirmButtonText: "確定" });
          }
        }).catch(() => {
          this.$alert("不准跨平台", "注意", { confirmButtonText: "確定" });
        });
      // 發送驗證碼
    },
    countdown() {
      this.coldDown--;
      if (this.coldDown == 0) {
        window.clearInterval(this.timer);
      }
    },
    openInputFile(type) {
      this.checkClientOrderId();
      if (!this.isVerify || !this.isMember) {
        this.$alert("請先驗證手機號碼", "注意", { confirmButtonText: "確定" });
        return;
      }
      switch (type) {
        case "pic1":
          this.$refs.fileBtn1.click();
          break;
        case "pic2":
          this.$refs.fileBtn2.click();
          break;
        case "pic3":
          this.$refs.fileBtn3.click();
          break;
        case "pic4":
          this.$refs.fileBtn4.click();
          break;
        }
      if (this.form.carNoDisName == null || this.form.carNoDisName.toString() == "") {
        this.$alert("請先選擇車輛", "結果", { confirmButtonText: "確定" });
        return;
      }
        switch (type) {
          case "picat1":
          this.$refs.fileBtnCat1.click();
          break;
        case "picat2":
          this.$refs.fileBtnCat2.click();
          break;
        case "picat3":
          this.$refs.fileBtnCat3.click();
          break;
        case "picat4":
          this.$refs.fileBtnCat4.click();
          break;
          
      }
      //
    },
    clear () {
      this.$refs.signaturePad.clearSignature()
    },
    changeEDate(hours) {
      this.$axios.get("../api/booking/getSysTime", {}).then(res => {
        this.sysTime = res.data;
        let time = Date.parse(this.sysTime);
        let d = new Date();
        d.setTime(time);
        if (this.isRentRightNow) {
          this.form.sDate = d;
        }
        d = new Date(d.getTime() + 24 * 1000 * 3600);
        let sDate_ = moment(this.form.sDate).format("YYYYMMDDHHmmss");
        if (this.isRentRightNow) {
          sDate_ = moment(this.sysTime).format("YYYYMMDDHHmmss");
        }
        let eDate_ = moment(sDate_, "YYYYMMDDHHmmss");
        switch (hours) {
          case "12":
            eDate_.add(12, "hours");
            this.form.eDate = eDate_;
            break;
          case "24":
            eDate_.add(24, "hours");
            this.form.eDate = eDate_;
            break;
          case "48":
            eDate_.add(48, "hours");
            this.form.eDate = eDate_;
            break;
        }
        if (this.selectedCar !== null){
          this.getPrice()
        }
      }).catch(() => {});
    },
    confirmStartRent() {
      if (this.form.clientUserId == "" || this.form.clientUserId == null) {
         this.$alert("請輸入有效會員帳號", "結果", {confirmButtonText: "確定"});
         return;
      }
      if (this.form.clientUserDisName == "" || this.form.clientUserDisName == null || this.form.phone == undefined) {
         this.$alert("會員名稱不能為空", "結果", {confirmButtonText: "確定"});
         return;
      }
      if (this.form.birthday == "" || this.form.birthday == null || this.form.phone == undefined) {
         this.$alert("出生日期不能為空", "結果", {confirmButtonText: "確定"});
         return;
      }
      if (this.form.phone == "" || this.form.phone == null || this.form.phone == undefined) {
         this.$alert("手機號碼不能為空", "結果", {confirmButtonText: "確定"});
         return;
      }
      if (this.pic1 =="" || this.pic2 =="" || this.pic3 =="" || this.pic4 =="") {
         this.$alert("證件照片不能為空", "結果", {confirmButtonText: "確定"});
         return;
      }
      if (this.form.carNoDisName == null || this.form.carNoDisName.toString() == "") {
         this.$alert("請先選擇車輛", "結果", { confirmButtonText: "確定" });
         return;
      }
      if (this.form.price == null || this.form.price.toString() == "") {
        this.$alert("請先輸入金額", "結果", { confirmButtonText: "確定" });
        return;
      }
      if (!this.agreeMemberRule) {
        this.$alert("請先閱讀並同意會員隱私條款", "注意", {confirmButtonText: "確定"});
        return;
      }
      if (!this.agreeStoreRule) {
        this.$alert("請先閱讀並同意租借條款", "注意", {confirmButtonText: "確定"});
        return;
      }
      let eDate = moment(this.form.eDate).format("YYYYMMDDHHmmss");
      let sDate = moment(this.form.sDate).format("YYYYMMDDHHmmss");
      let now = moment(this.sysTime).format("YYYYMMDDHHmmss");
      if (sDate >= eDate) {
        this.$alert("還車日期不可比取車日期早", "結果", {confirmButtonText: "確定"});
        return;
      }
      if (now >= eDate) {
        this.$alert("還車日期不可比現在日期早", "結果", {confirmButtonText: "確定"});
        return;
      }
      if (this.form.clientOrderId == "" || this.form.clientOrderId == null) {
        this.$confirm(this.form.carNoDisName + "確定出租嗎?", "注意", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.confirmOnePageStartRentAndCreateNewClientOrder();
        });
      } else {
        let sub = "";
        if (this.form.flow != 50) {
          sub = "不收訂金，";
        }
        this.$confirm(this.form.carNoDisName + sub + "確定出租嗎?", "注意", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.startOnePageRentFromExistClientOrder();
        });
      }
    },
    startOnePageRentFromExistClientOrder() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$alert("請簽名", "注意", { confirmButtonText: "確定" });
        return;
      }
      let eDate = moment(this.form.eDate).format("YYYYMMDDHHmmss")
      this.fullscreenLoading = true;
      let pa = {
        clientOrderId: this.form.clientOrderId,
        catId: this.selectedCar.catId,
        eDate: eDate,
        startDistance: this.selectedCar.nowDistance,
        prodId: this.form.prodId, // 確定的車牌
        plusInsurance: this.form.plusInsurance ? "1" : "0",
        reserveNote: this.form.reserveNote,
        insurancePrice: "",
        price: this.form.price,
        deposit: this.form.deposit,
        raincoat: this.form.raincoat,
        helmet: this.form.helmet,
        helmetFull: this.form.helmetFull,
        sign: data
      };
      if (this.form.rentDateType == 2) {
        pa.insurancePrice = 0;
        pa.plusInsurance = "0";
      }
      if (this.form.beforePay === "T") {
        pa.beforePrice = parseInt(this.form.price.toString()) - parseInt(this.form.beforeDiscount.toString()) - parseInt(this.form.deposit.toString());
        pa.beforeDiscount = parseInt(this.form.beforeDiscount.toString())
        pa.beforeOrginPrice = pa.beforePrice + parseInt(this.form.beforeDiscount.toString())
        pa.beforePay = this.form.beforePay;
        pa.price = pa.price - pa.beforeDiscount
      }

      let url = "../api/onePageGet/startOnePageRentFromExistClientOrder";
      this.$axios.post(url, pa)
        .then(res => {
          this.fullscreenLoading = false;
          this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
          location.reload();
        }).catch(() => {
          this.fullscreenLoading = false;
          this.$alert("服務器錯誤", "結果", { confirmButtonText: "確定" });
          location.reload();
        });
    },
    confirmOnePageStartRentAndCreateNewClientOrder() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$alert("請簽名", "注意", { confirmButtonText: "確定" });
        return;
      }
      this.form.clientUserId = this.form.clientUserId.toUpperCase();
      let eDate = moment(this.form.eDate).format("YYYYMMDDHHmmss");
      let sDate = null;
      if (!this.isRentRightNow) {
        sDate = moment(this.form.sDate).format("YYYYMMDDHHmmss");
      }
      let params = {
        clientUserId: this.form.clientUserId,
        tempClinetOrderId:this.tempClinetOrderId,
        disName: this.form.clientUserDisName,
        phone: this.form.phone,
        birthday: this.form.birthday,
        storeId: this.form.storeId,
        carNo: this.form.carNo,
        nowDistance: this.selectedCar.nowDistance,
        price: this.form.price,
        plusInsurance: this.form.plusInsurance ? "1" : "0",
        deposit: 0,
        sDate: sDate,
        eDate: eDate,
        checkCode: this.form.checkCode,
        reserveNote: this.form.reserveNote,
        rentDateType: this.form.rentDateType,
        helmet: this.form.helmet,
        helmetFull: this.form.helmetFull,
        raincoat: this.form.raincoat,
        beforePay: this.form.beforePay,
        sign: data
      };
      if (this.form.beforePay === "T") {
        params.beforePrice = parseInt(this.form.price.toString()) - parseInt(this.form.beforeDiscount.toString());
        params.beforeDiscount = parseInt(this.form.beforeDiscount.toString())
        params.beforeOrginPrice = params.beforePrice + parseInt(this.form.beforeDiscount.toString())
        params.beforePayMethod = "CASH";
      }
      let url = "../api/onePageGet/confirmOnePageStartRentAndCreateNewClientOrder";
      this.fullscreenLoading = true;
      this.$axios.post(url, params)
        .then(res => {
          this.fullscreenLoading = false;
          location.reload();
          this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
        }).catch(() => {
          this.fullscreenLoading = false;
          this.$alert("租車失敗，請洽系統管理員", "結果", {
            confirmButtonText: "確定"
          });
          location.reload();
        });
    },
    confirmStartRentAndBeforePay() {
      if (this.form.clientUserId == "" || this.form.clientUserId == null) {
        this.$alert("請輸入有效會員帳號", "結果", {confirmButtonText: "確定"});
        return;
      }
      if (this.form.carNoDisName == null || this.form.carNoDisName.toString() == "") {
        this.$alert("請先選擇車輛", "結果", { confirmButtonText: "確定" });
        return;
      }
      if (this.form.price == null || this.form.price.toString() == "") {
        this.$alert("請先輸入金額", "結果", { confirmButtonText: "確定" });
        return;
      }
      if (!this.agreeMemberRule) {
        this.$alert("請先閱讀並同意會員隱私條款", "注意", {confirmButtonText: "確定"});
        return;
      }
      if (!this.agreeStoreRule) {
        this.$alert("請先閱讀並同意會員隱私條款", "注意", {confirmButtonText: "確定"});
        return;
      }
      if (!this.agreeStoreRule) {
        this.$alert("請先閱讀並同意租借條款", "注意", {confirmButtonText: "確定"});
        return;
      }
      let eDate = moment(this.form.eDate).format("YYYYMMDDHHmmss");
      let sDate = moment(this.form.sDate).format("YYYYMMDDHHmmss");
      let now = moment(this.sysTime).format("YYYYMMDDHHmmss");
      if (sDate >= eDate) {
        this.$alert("還車日期不可比取車日期早", "結果", {confirmButtonText: "確定"});
        return;
      }
      if (now >= eDate) {
        this.$alert("還車日期不可比現在日期早", "結果", {confirmButtonText: "確定"});
        return;
      }
      this.$confirm( this.form.carNoDisName + "確定會員預付" + (this.form.price - this.form.beforeDiscount) + "元，出租車號" + this.form.carNoDisName + "嗎?", "注意",
        {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
        if (this.form.clientOrderId == "" || this.form.clientOrderId == null) {
          this.confirmOnePageStartRentAndCreateNewClientOrder();
        } else {
          this.startOnePageRentFromExistClientOrder();
        }
      });
    },
    to(path) {
      this.$emit("gotoMenu", path.substring(1));
      this.$router.push({ path: path });
    },
    getPrice() {
      if (this.enabledDistance === 'F') {
        this.form.nowDistance = '0'
      }
      //取車作業使用
      let sDate=moment(this.form.sDate).format('YYYYMMDDHHmmss');
      let eDate = moment(this.form.eDate).format('YYYYMMDDHHmmss')

      this.isCal = true
      this.form.priceList = Object.assign([])
        
      let param={
        clientOrderId: this.form.clientOrderId,
        prodPriceRowId: this.selectedCar.prodPriceRowId,
        prodStockId: this.selectedCar.prodStockId,
        sDate: sDate,
        eDate: eDate,
        plusInsurance: this.form.plusInsurance ? "1" : "0",
      };
      this.$axios
        .get('../api/onePageGet/getPrice', { params: param })
        .then((res)=>{
          this.isCal=false
          if (res.data.rs === 'success') {
            let ps = res.data.priceList
            this.form.price = 0
            this.form.deposit = 0
            for (var key in ps) {
              if (key === 'rideHour') {
                this.rideHour = ps[key]
              } else {
                if (key === 'ridePrice') {
                  // ps[key] = 3
                  this.form.price = this.form.price + ps[key]
                  this.costPrice = this.costPrice + ps[key]
                  this.form.priceList.push({ name: '車資費用:', amt: ps[key] })
                  // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                  if (res.data.additionNote !== undefined) {
                    this.form.priceList.push({ name: '(含超額里程費):', amt: res.data.additionNote.overDistance })
                  }
                }else if (key === 'isrPrice') {
                  if (ps['ridePrice'] === 0) {
                    ps[key] = 0
                  }
                  this.form.price = this.form.price + ps[key] 
                  this.costPrice = this.costPrice + ps[key] //車資
                  this.form.priceList.push({ name: '保障費用:', amt: ps[key] })
                } else if (key === 'deposit') {
                  if (ps[key] > 0) {
                    this.form.deposit = ps[key]
                  }
                }
              }
            }
            this.form.detailPrice = this.form.price
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
        }).catch(()=>{
            this.isCal=false
            this.$alert('計算失敗請洽系統管理者', '結果', { confirmButtonText: '確定' });
        });   
    },
    changePrice() {
      this.isPriceChange = "T";
    }
  },
  mounted() {
    this.signBord = document.getElementById("signBord");
    this.signBord.addEventListener( "touchmove", (this.touchMonveHandle = function(e) {
        e.preventDefault();}), { passive: false });
  },
  beforeDestroy() {
    document.body.removeEventListener("touchmove", this.touchMonveHandle);
  }
};
</script>
<style scoped>
.storIdSpan{
  margin-bottom: 20px;
}
.photo {
  height: 230px;
}
.terms-of-service {
  border: 1px solid #686868;
  border-radius: 8px;
  width: 95%;
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 16px;
  margin-bottom: 18px;
  text-align: left;
}
.upload-box {
  width: 95%;
  height: 230px;
  border: 1px solid #686868;
  border-radius: 8px;
  color: #bfbfbf;
  margin-bottom: 16px;
}
.selectit {
  background-color: yellowgreen;
}
.empty {
  background-color: white;
}
</style>
